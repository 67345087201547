import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import Header from '../header';
import { styles } from './styles';

const Layout = ({ children }) => {
  const location = useLocation();
  return (
    <Box>
      {location.pathname === '/' || location?.pathname.split('/')[3] === 'sheet' ? null : (
        <Header />
      )}
      <main style={styles.layoutWrapper}>{children}</main>
    </Box>
  );
};
export default Layout;
