import { Box, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';

import ChevronDownIcon from '../../../assets/icons/ChevronDownIcon';
import { styles } from '../styles';

export const SortingMenu = () => {
  return (
    <Menu>
      <MenuButton as={Box} sx={styles.sortMenuBtn} variant='unstyled'>
        <Flex>
          <Text> Date created</Text>
          <ChevronDownIcon />
        </Flex>
      </MenuButton>
      <MenuList sx={styles.sortMenuList}>
        <MenuItem sx={styles.sortMenuItem}>Date created</MenuItem>
      </MenuList>
    </Menu>
  );
};
