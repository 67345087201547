import { Avatar, AvatarBadge, Box, Flex, Text } from '@chakra-ui/react';

import avatar from '../../assets/images/Den-Lavrik.jpg';
import { styles } from './styles';

const ProfileMenu = () => {
  return (
    <Flex ml='1.042vw'>
      <Box sx={styles.userInfoWrapper}>
        <Text sx={styles.userNameText}>Den Lavrik</Text>
        <Text sx={styles.userRoleText}>Admin</Text>
      </Box>
      <Avatar sx={styles.avatar} src={avatar}>
        <AvatarBadge sx={styles.badge} />
      </Avatar>
    </Flex>
  );
};

export default ProfileMenu;
