import React from 'react';

const FolderOpenIcon = ({ fill = '#000' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='0.833vw'
      height='0.833vw'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill={fill}
        d='M2 14a.666.666 0 01-.667-.667V2.667A.667.667 0 012 2h4.943l1.333 1.333h5.057A.667.667 0 0114 4v2h-1.333V4.667H7.724L6.391 3.333H2.667v7.999l1-3.999H15l-1.54 6.162a.666.666 0 01-.647.505H2zm11.292-5.333H4.708l-1 4h8.584l1-4z'
      ></path>
    </svg>
  );
};

export default FolderOpenIcon;
