import React from 'react';

const FolderLineIcon = ({ fill = '#000' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='0.833vw'
      height='0.833vw'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill={fill}
        d='M2.667 3.333v9.334h10.666v-8H7.724L6.391 3.333H2.667zm5.609 0H14a.666.666 0 01.667.667v9.333A.666.666 0 0114 14H2a.666.666 0 01-.667-.667V2.667A.667.667 0 012 2h4.943l1.333 1.333z'
      ></path>
    </svg>
  );
};

export default FolderLineIcon;
