export const styles = {
  userInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  userNameText: {
    color: 'gray.400',
    fontSize: '0.729vw',
  },
  userRoleText: {
    color: 'gray.400',
    fontSize: '0.573vw',
  },
  avatar: {
    height: '2.083vw',
    width: '2.083vw',
    marginLeft: '0.833vw',
  },
  badge: {
    height: '0.873vw',
    width: '0.873vw',
    background: 'green.100',
    border: '0.156vw solid white',
    position: 'absolute',
    top: '1.1vw',
  },
};
