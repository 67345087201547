export const styles = {
  pageWrapper: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  authHeading: {
    color: 'gray.400',
    fontWeight: 600,
    fontSize: '1.250vw',
    lineHeight: '117%',
    alignSelf: 'center',
    marginBottom: '0.729vw',
  },
  inputLabel: {
    fontSize: '0.833vw',
    lineHeight: '140%',
    fontWeight: 600,
    marginTop: '0.833vw',
    marginBottom: '0.625vw',
  },
  forgotPasswordText: {
    color: 'purple.100',
    textDecoration: 'underline',
    marginTop: '0.833vw',
    marginBottom: '1.563vw',
    fontSize: '0.833vw',
    lineHeight: '140%',
    cursor: 'pointer',
    fontWeight: 600,
    alignSelf: 'flex-end',
  },
  dividerStyle: {
    marginTop: '1.563vw',
    marginBottom: '1.563vw',
    background: 'gray.50',
    height: '0.052vw',
  },
  noAccountText: {
    fontSize: '0.833vw',
    color: 'gray.300',
    lineHeight: '140%',
    fontWeight: 600,
  },
  loginBtn: {
    width: '8.333vw',
    fontSize: '0.729vw',
    alignSelf: 'center',
  },
  signupBtn: {
    minWidth: '4.7vw',
    padding: '0vw',
    color: 'purple.100',
    fontSize: '0.729vw',
    maxHeight: '1.250vw',
    minHeight: '1.250vw',
    borderRadius: '0vw',
  },
  signupWrapper: {
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '0.625vw',
  },
};
