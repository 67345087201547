import React from 'react';

const NewspaperIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='4.271vw'
      height='4.271vw'
      fill='none'
      viewBox='0 0 82 82'
    >
      <path
        fill='#7368F0'
        d='M54.5 68.333V13.667H14v51.25c0 .906.356 1.775.989 2.416.632.64 1.49 1 2.386 1H54.5zm10.125 6.834h-47.25c-2.685 0-5.26-1.08-7.16-3.002a10.315 10.315 0 01-2.965-7.248V10.25c0-.906.356-1.775.989-2.416.632-.64 1.49-1 2.386-1h47.25c.895 0 1.754.36 2.386 1 .633.64.989 1.51.989 2.416v23.917h13.5v30.75c0 2.718-1.067 5.325-2.966 7.248a10.063 10.063 0 01-7.159 3.002zM61.25 41v23.917c0 .906.356 1.775.989 2.416.632.64 1.49 1 2.386 1 .895 0 1.754-.36 2.386-1 .633-.641.989-1.51.989-2.416V41h-6.75zm-40.5-20.5H41V41H20.75V20.5zm6.75 6.833v6.834h6.75v-6.834H27.5zm-6.75 17.084h27v6.833h-27v-6.833zm0 10.25h27V61.5h-27v-6.833z'
      ></path>
    </svg>
  );
};

export default NewspaperIcon;
