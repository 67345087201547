import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

import { SHEETDATA } from '../../../../mock-data';
import { styles } from '../styles';
import Checkbox from './checkbox';
import CheckboxList from './checkbox-list';

export const ElementsTree = ({ setSelectedItems }) => {
  const defaultItemStates = SHEETDATA.elements.map((element) => ({
    id: element?.id,
    state: 'unchecked',
    type: element?.type,
    properties: element?.properties,
    parentId: element?.parentId,
  }));

  const [itemStates, setItemStates] = React.useState(defaultItemStates);
  const [generalCheckboxState, setGeneralCheckboxState] = React.useState(false);

  React.useEffect(() => {
    const generalFolders = itemStates?.filter(
      (item) => item.type === 'folder' && item?.parentId === null,
    );
    generalFolders.map((folder) => {
      if (folder?.state === 'unchecked') {
        setGeneralCheckboxState(false);
      }
      if (generalFolders.length === generalFolders.filter((i) => i.state === 'checked').length) {
        setGeneralCheckboxState(true);
      }
      return null;
    });
  }, [itemStates]);

  const updateItemStates = (oldState, items, clickedIds) => {
    const newState = oldState.map((i) => ({ ...i }));
    // getters
    const getItemState = (id) => {
      return newState.find((i) => i.id === id).state;
    };
    // setters
    const updateParent = (id) => {
      const item = items.find((i) => i.id === id);
      const parent = items.find((i) => i.id === item.parentId);
      if (!parent) return;
      const childIds = items.filter((i) => i.parentId === parent.id).map((i) => i.id);
      const childStates = childIds.map((childId) => getItemState(childId));
      if (childStates.length === childStates.filter((s) => s === 'checked').length) {
        newState.find((i) => i.id === parent.id).state = 'checked';
      } else if (childStates.length === childStates.filter((s) => s === 'unchecked').length) {
        newState.find((i) => i.id === parent.id).state = 'unchecked';
      } else {
        newState.find((i) => i.id === parent.id).state = 'indeterminate';
      }
      updateParent(parent.id);
    };
    const setUnchecked = (id) => {
      newState.find((i) => i.id === id).state = 'unchecked';
      items
        .filter((i) => i.parentId === id)
        .map((i) => i.id)
        .forEach((childId) => setUnchecked(childId));
      updateParent(id);
    };
    const setChecked = (id) => {
      newState.find((i) => i.id === id).state = 'checked';
      items
        .filter((i) => i.parentId === id)
        .map((i) => i.id)
        .forEach((childId) => setChecked(childId));
      updateParent(id);
    };
    // actual logic
    if (typeof clickedIds !== 'object') {
      const itemState = getItemState(clickedIds);
      if (itemState === 'checked') {
        setUnchecked(clickedIds);
      } else {
        setChecked(clickedIds);
      }
    } else {
      clickedIds?.map((clickedId) => {
        const itemState = getItemState(clickedId);
        if (itemState === 'checked') {
          setUnchecked(clickedId);
        } else {
          setChecked(clickedId);
        }
        return null;
      });
    }
    setSelectedItems(newState);
    return newState;
  };
  const getStateForId = React.useCallback(
    (id) => {
      return itemStates.find((i) => i.id === id).state;
    },
    [itemStates],
  );
  const clickHandler = React.useCallback(
    (ids) => setItemStates(updateItemStates(itemStates, SHEETDATA.elements, ids)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemStates],
  );

  const handleSwitchAll = () => {
    const folderIds = [];
    const folders = itemStates?.filter(
      (element) => element?.type === 'folder' && element?.parentId === null,
    );
    if (!generalCheckboxState) {
      folders
        .filter((i) => i.state === 'unchecked' || i.state === 'indeterminate')
        .map((folder) => {
          folderIds.push(folder.id);
          return null;
        });
    }
    if (generalCheckboxState) {
      folders
        .filter((i) => i.state === 'checked')
        .map((folder) => {
          folderIds.push(folder.id);
          return null;
        });
    }
    clickHandler(folderIds);
  };

  return (
    <Flex sx={styles.itemsTreeWrapper}>
      <Flex flexDirection='column' mr='0.25vw'>
        <Flex alignItems='center' justifyContent='space-between' mb='1.354vw'>
          <Text sx={styles.sheetItemsText}>{SHEETDATA?.sheetName} items</Text>
          <Checkbox
            onClick={() => {
              handleSwitchAll();
            }}
            isChecked={generalCheckboxState}
          />
        </Flex>
        <CheckboxList
          items={SHEETDATA.elements}
          onClick={clickHandler}
          getStateForId={getStateForId}
        />
      </Flex>
    </Flex>
  );
};
