const BookIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1.042vw'
      height='1.042vw'
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        fill='#625F6E'
        d='M10.833 17.5v2.083l-2.5-1.666-2.5 1.666V17.5h-.416A2.917 2.917 0 012.5 14.583V4.167a2.5 2.5 0 012.5-2.5h11.667a.833.833 0 01.833.833v14.167a.833.833 0 01-.833.833h-5.834zm-5-1.667v-1.666h5v1.666h5v-2.5H5.417a1.25 1.25 0 000 2.5h.416zm0-11.666v1.666H7.5V4.167H5.833zm0 2.5v1.666H7.5V6.667H5.833zm0 2.5v1.666H7.5V9.167H5.833z'
      ></path>
    </svg>
  );
};

export default BookIcon;
