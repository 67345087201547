/* eslint-disable prettier/prettier */
import s1 from '../assets/images/13-1.png';
import s2 from '../assets/images/16-1.png';
import s3 from '../assets/images/27-1.png';
import s4 from '../assets/images/39-1.png';
import s5 from '../assets/images/56-1.png';
import s6 from '../assets/images/61-1.png';
import s7 from '../assets/images/64-1.png';
import s8 from '../assets/images/66-1.png';
import s9 from '../assets/images/69-1.png';
import s10 from '../assets/images/71-1.png';
import s11 from '../assets/images/72-1.png';
import s12 from '../assets/images/75-1.png';
import s13 from '../assets/images/78-1.png';
import s14 from '../assets/images/79-1.png';
import s15 from '../assets/images/80-1.png';
import s16 from '../assets/images/89-1.png';
import s17 from '../assets/images/93-1.png';
import s18 from '../assets/images/97-1.png';
import s19 from '../assets/images/98-1.png';
import s20 from '../assets/images/108-1.png';
import s21 from '../assets/images/113-1.png';
import s22 from '../assets/images/116-1.png';
import s23 from '../assets/images/120-1.png';
import s24 from '../assets/images/123-1.png';
import s25 from '../assets/images/125-1.png';
import s26 from '../assets/images/148-1.png';
import s27 from '../assets/images/149-1.png';
import s28 from '../assets/images/151-1.png';

export const PROJECT_DATA = {
  projectName: 'Super House',
  folders: [
    {
      folderName: 'Source files',
      depthLevel: 0,
      files: null,
      folders: [
        {
          folderName: 'Drawings',
          depthLevel: 1,
          files: [
            {
              fileName: 'BGH Hospital',
              depthLevel: 2,
              processing: true,
              sheets: [
                {
                  sheetName: 'Electric plan',
                  previewImage: s1,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan rev2',
                  previewImage: s2,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall EDITED',
                  previewImage: s3,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electrical plan Hall',
                  previewImage: s4,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall EDITED',
                  previewImage: s5,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Bathroom',
                  previewImage: s6,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan v3',
                  previewImage: s7,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Software v7.0',
                  previewImage: s8,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan',
                  previewImage: s9,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan rev2',
                  previewImage: s10,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall EDITED',
                  previewImage: s11,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall',
                  previewImage: s12,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall EDITED',
                  previewImage: s13,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Bathroom',
                  previewImage: s14,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan v3',
                  previewImage: s15,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan',
                  previewImage: s16,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan rev2',
                  previewImage: s17,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall EDITED',
                  previewImage: s18,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall',
                  previewImage: s19,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall EDITED',
                  previewImage: s20,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Bathroom',
                  previewImage: s21,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan v3',
                  previewImage: s22,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Software v7.0',
                  previewImage: s23,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan',
                  previewImage: s24,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan rev2',
                  previewImage: s25,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall EDITED',
                  previewImage: s26,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall',
                  previewImage: s27,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Software v7.0',
                  previewImage: s28,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
              ],
            },
          ],
          folders: null,
        },
        {
          folderName: 'Models',
          depthLevel: 1,
          files: [
            {
              fileName: 'BGH Hospital',
              depthLevel: 2,
              processing: true,
              sheets: [
                {
                  sheetName: 'Electric plan',
                  previewImage: s1,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan rev2',
                  previewImage: s2,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall EDITED',
                  previewImage: s3,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electrical plan Hall',
                  previewImage: s4,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall EDITED',
                  previewImage: s5,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Bathroom',
                  previewImage: s6,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan v3',
                  previewImage: s7,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Software v7.0',
                  previewImage: s8,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan',
                  previewImage: s9,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan rev2',
                  previewImage: s10,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall EDITED',
                  previewImage: s11,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall',
                  previewImage: s12,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall EDITED',
                  previewImage: s13,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Bathroom',
                  previewImage: s14,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan v3',
                  previewImage: s15,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan',
                  previewImage: s16,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan rev2',
                  previewImage: s17,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall EDITED',
                  previewImage: s18,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall',
                  previewImage: s19,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall EDITED',
                  previewImage: s20,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Bathroom',
                  previewImage: s21,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan v3',
                  previewImage: s22,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Software v7.0',
                  previewImage: s23,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan',
                  previewImage: s24,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan rev2',
                  previewImage: s25,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall EDITED',
                  previewImage: s26,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall',
                  previewImage: s27,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Software v7.0',
                  previewImage: s28,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
              ],
            },
          ],
          folders: null,
        },
        {
          folderName: 'Rules',
          depthLevel: 1,
          files: [
            {
              fileName: 'BGH Hospital',
              depthLevel: 2,
              processing: true,
              sheets: [
                {
                  sheetName: 'Electric plan',
                  previewImage: s1,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan rev2',
                  previewImage: s2,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall EDITED',
                  previewImage: s3,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electrical plan Hall',
                  previewImage: s4,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall EDITED',
                  previewImage: s5,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Bathroom',
                  previewImage: s6,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan v3',
                  previewImage: s7,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Software v7.0',
                  previewImage: s8,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan',
                  previewImage: s9,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan rev2',
                  previewImage: s10,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall EDITED',
                  previewImage: s11,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall',
                  previewImage: s12,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall EDITED',
                  previewImage: s13,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Bathroom',
                  previewImage: s14,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan v3',
                  previewImage: s15,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan',
                  previewImage: s16,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan rev2',
                  previewImage: s17,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall EDITED',
                  previewImage: s18,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall',
                  previewImage: s19,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall EDITED',
                  previewImage: s20,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Bathroom',
                  previewImage: s21,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan v3',
                  previewImage: s22,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Software v7.0',
                  previewImage: s23,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan',
                  previewImage: s24,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan rev2',
                  previewImage: s25,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall EDITED',
                  previewImage: s26,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Electric plan Hall',
                  previewImage: s27,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
                {
                  sheetName: 'Software v7.0',
                  previewImage: s28,
                  sheetType: 'Electrics',
                  latestRevision: '106.1',
                  pageSize: '12 580 x 16 650 px',
                  scale: '15%',
                  uploadDate: '06/12/2022',
                  uploadedBy: 'Username',
                },
              ],
            },
          ],
          folders: null,
        },
      ],
    },
    { folderName: 'Drawing index', depthLevel: 0, files: null, folders: null },
    { folderName: 'Elements', depthLevel: 0, files: null, folders: null },
  ],
};
