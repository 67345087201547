import React from 'react';

const ErrorWarningIcon = ({ fill }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1.042vw'
      height='1.042vw'
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        fill={fill}
        d='M10 18.333a8.333 8.333 0 110-16.666 8.333 8.333 0 110 16.666zm0-1.666a6.666 6.666 0 100-13.333 6.666 6.666 0 000 13.333zM9.167 12.5h1.666v1.667H9.167V12.5zm0-6.667h1.666v5H9.167v-5z'
      ></path>
    </svg>
  );
};

export default ErrorWarningIcon;
