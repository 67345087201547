import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Flex,
  HStack,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { scroller } from 'react-scroll';

import ChevronRightIcon from '../../../assets/icons/ChevronRightIcon';
import DownloadIcon from '../../../assets/icons/DownloadIcon';
import DragMoveIcon from '../../../assets/icons/DragMoveIcon';
import ErrorWarningIcon from '../../../assets/icons/ErrorWarningIcon';
import FileListIcon from '../../../assets/icons/FileListIcon';
import FolderLineIcon from '../../../assets/icons/FolderLineIcon';
import FolderOpenIcon from '../../../assets/icons/FolderOpen';
import FolderUploadIcon from '../../../assets/icons/FolderUploadIcon';
import GearListIcon from '../../../assets/icons/GearList';
import ListCheckIcon from '../../../assets/icons/ListCheckIcon';
import SearchIcon from '../../../assets/icons/SearchIcon';
import errorReport from '../../../assets/report-document/Error Report.pdf';
import video from '../../../assets/video/example.mp4';
import Card from '../../../components/card';
import TextField from '../../../components/text-field';
import { PROJECTS } from '../../../mock-data';
import { SheetCard } from './components/sheet-card';
import { Videoplayer } from './components/video-player';
import { styles } from './styles';

const ProjectPage = () => {
  const [activeMode, setActiveMode] = React.useState('Browse');
  const [currentSheet, setCurrentSheet] = React.useState(1);
  const [selectedFile, setSelectedFile] = React.useState('');

  const { id } = useParams();

  React.useEffect(() => {
    if (selectedFile) {
      scroller.scrollTo(`sheet${currentSheet}`, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'scroll-container',
      });
    }
  }, [currentSheet, selectedFile]);

  const handleFileSearch = (projectData) => {
    const query = selectedFile?.split('/');
    let arrayDepth = 0;
    if (selectedFile) {
      return projectData?.folders
        ?.map((projectFolder) => {
          arrayDepth += 1;
          if (projectFolder?.folderName !== query[0]) {
            return handleFileSearch(projectFolder);
          }
          if (projectFolder?.folderName === query[0]) {
            return projectFolder?.files.find((folderFile) => folderFile?.fileName === query[1]);
          }
          return null;
        })
        .flat(arrayDepth)
        .find((item) => item !== undefined);
    }
    return null;
  };

  const handleSelectFileOnLoad = (data) => {
    let arrayDepth = 0;
    let result = null;
    result = data?.folders
      ?.map((folder) => {
        arrayDepth += 1;
        if (folder.files) {
          return [folder.folderName, folder?.files[0]];
        }
        if (!folder.files) {
          return handleSelectFileOnLoad(folder);
        }
        return null;
      })
      .flat(arrayDepth);
    return result;
  };

  React.useEffect(() => {
    setSelectedFile(
      `${handleSelectFileOnLoad(PROJECTS[id])[0]}/${
        handleSelectFileOnLoad(PROJECTS[id])[1]?.fileName
      }`,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderFileAccordion = () => {
    const mapProjectContent = (projectContent) => {
      if (projectContent?.folders) {
        return projectContent.folders.map((folder, idx) => (
          <Box key={idx}>
            <Accordion allowMultiple>
              <AccordionItem mt='0.625vw'>
                {({ isExpanded }) => (
                  <>
                    <AccordionButton>
                      <Flex alignItems='center' ml={`${0.521 + folder.depthLevel / 2}vw`}>
                        {isExpanded ? <FolderOpenIcon /> : <FolderLineIcon />}
                        <Text ml='0.313vw'>{folder.folderName}</Text>
                      </Flex>
                      <AccordionIcon fontSize='1.042vw' />
                    </AccordionButton>
                    <AccordionPanel mt='0.625vw'>
                      {folder?.files?.map((file, fileIdx) => (
                        <Flex
                          key={folder.folderName + fileIdx}
                          sx={styles.accordionFileWrapper}
                          background={
                            selectedFile === `${folder.folderName}/${file.fileName}`
                              ? 'purple.100'
                              : 'unset'
                          }
                          onClick={() => setSelectedFile(`${folder.folderName}/${file.fileName}`)}
                          pl={`${0.521 + file.depthLevel / 2}vw`}
                        >
                          <FileListIcon />
                          <Text ml='0.313vw'>{file.fileName}</Text>
                        </Flex>
                      ))}
                      {mapProjectContent(folder)}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
            {folder?.depthLevel === 0 && projectContent.folders.length > idx + 1 ? (
              <Box sx={styles.divider} />
            ) : null}
          </Box>
        ));
      }
      return null;
    };

    return (
      <Flex>
        <Accordion sx={styles.accordionWrapper} variant='unstyled' allowMultiple>
          <AccordionItem>
            <AccordionButton sx={styles.accordionBtn}>
              <Text sx={styles.accordionTitleText}>{PROJECTS[id]?.name}</Text>
              <AccordionIcon fontSize='1.042vw' />
            </AccordionButton>
            <AccordionPanel>{mapProjectContent(PROJECTS[id])}</AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
    );
  };

  const renderModeBtns = () => {
    return (
      <HStack sx={styles.rightBlock} spacing='0.052vw'>
        <Button
          sx={styles.browseBtn}
          variant={activeMode === 'Browse' ? 'primary' : 'secondary'}
          onClick={() => setActiveMode('Browse')}
        >
          <ListCheckIcon fill={activeMode === 'Browse' ? '#fff' : '#625F6E'} />
          <Text ml='0.313vw'>Browse</Text>
          {activeMode === 'Browse' ? <Box sx={styles.activeDot} /> : null}
        </Button>
        <Button
          sx={styles.searchBtn}
          variant={activeMode === 'Search' ? 'primary' : 'secondary'}
          onClick={() => setActiveMode('Search')}
        >
          <SearchIcon fill={activeMode === 'Search' ? '#fff' : '#625F6E'} />
          <Text ml='0.313vw'>Search</Text>
          {activeMode === 'Search' ? <Box sx={styles.activeDot} /> : null}
        </Button>
        <Button
          sx={styles.rulesBtn}
          variant={activeMode === 'Rules' ? 'primary' : 'secondary'}
          onClick={() => setActiveMode('Rules')}
        >
          <FolderUploadIcon fill={activeMode === 'Rules' ? '#fff' : '#625F6E'} />
          <Text ml='0.313vw'>Rules</Text>
          {activeMode === 'Rules' ? <Box sx={styles.activeDot} /> : null}
        </Button>
        <Link to={errorReport} target='_blank' download>
          <Button
            sx={styles.errorReportBtn}
            variant={activeMode === 'Error' ? 'primary' : 'secondary'}
            // onClick={() => setActiveMode('Error')}
          >
            <ErrorWarningIcon fill={activeMode === 'Error' ? '#fff' : '#625F6E'} />
            <Text ml='0.313vw'>Error Report</Text>
            {activeMode === 'Error' ? <Box sx={styles.activeDot} /> : null}
          </Button>
        </Link>
        <Button
          sx={styles.modelBtn}
          variant={activeMode === 'Model' ? 'primary' : 'secondary'}
          onClick={() => setActiveMode('Model')}
        >
          <DragMoveIcon fill={activeMode === 'Model' ? '#fff' : '#625F6E'} />
          <Text ml='0.313vw'>3D Model</Text>
          {activeMode === 'Model' ? <Box sx={styles.activeDot} /> : null}
        </Button>
      </HStack>
    );
  };

  return (
    <Box sx={styles.projectsWrapper}>
      <Card wrapperProps={{ height: '7vh', width: '98.125vw', padding: '0.833vw 0.729vw' }}>
        <Box sx={styles.projectsHeader}>
          <Flex alignItems='center'>
            <Text sx={styles.projectsHeading}>{PROJECTS[id]?.name}</Text>
            <Box sx={styles.verticalDivider} />
            <Breadcrumb separator={<ChevronRightIcon />} spacing='0.104vw' fontSize='1vw'>
              {selectedFile?.split('/').map((breadcrumb, idx) => (
                <BreadcrumbItem key={idx}>
                  <Text sx={styles.breadcumbText} color={idx === 0 ? 'purple.100' : 'gray.500'}>
                    {breadcrumb}
                  </Text>
                </BreadcrumbItem>
              ))}
            </Breadcrumb>
          </Flex>
          {renderModeBtns()}
        </Box>
      </Card>
      <Box sx={styles.pageContentWrapper}>
        <Card wrapperProps={{ width: '16.146vw', padding: '0.938vw', height: '81vh' }}>
          {renderFileAccordion(PROJECTS[id])}
        </Card>
        <Card
          wrapperProps={{
            width: '81.354vw',
            height: '81vh',
            marginLeft: '0.625vw',
            padding: '1.667vw 0.938vw 0.938vw 0.938vw',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {activeMode !== 'Rules' ? (
            <>
              <Flex sx={styles.headingWrapper}>
                <Text sx={styles.projectNameText}>{PROJECTS[id]?.name}</Text>
                <Flex>
                  <Button variant='borderless' sx={styles.organizeProjectBtn}>
                    <Flex alignItems='center'>
                      <GearListIcon />
                      <Text ml='0.313vw'>Organize Project</Text>
                    </Flex>
                  </Button>
                  <Button variant='borderless' sx={styles.downloadBtn}>
                    <Flex alignItems='center'>
                      <DownloadIcon />
                      <Text ml='0.313vw'>Download</Text>
                    </Flex>
                  </Button>
                </Flex>
              </Flex>
              <Flex sx={styles.infoBlocksWrapper}>
                <Box sx={styles.sheetListWrapper}>
                  <Box sx={styles.sheetListInnerWrapper} id='scroll-container'>
                    <SimpleGrid columns='4' spacingX='1.8vw' spacingY='1.250vw' w='99%'>
                      {handleFileSearch(PROJECTS[id])?.sheets?.map((sheetData, idx) => (
                        <Box key={idx} id={`sheet${idx + 1}`}>
                          <SheetCard sheedtData={sheetData} index={idx} />
                        </Box>
                      ))}
                    </SimpleGrid>
                  </Box>
                </Box>
                <Box sx={styles.fileInfoWrapper}>
                  <Text sx={styles.fileInfoTitle}>Category:</Text>
                  <Text sx={styles.fileInfoSubtitle}>Electric</Text>
                  <Box sx={styles.fileInfoDivider} />
                  <Text sx={styles.fileInfoTitle}>Sheets:</Text>
                  <Text sx={styles.fileInfoSubtitle}>{PROJECTS[id]?.filesCount}</Text>
                  <Box sx={styles.fileInfoDivider} />
                  <Text sx={styles.fileInfoTitle}>Processing date:</Text>
                  <Text sx={styles.fileInfoSubtitle}>06/12/2022</Text>
                  <Box sx={styles.fileInfoDivider} />
                  <Text sx={styles.fileInfoTitle}>Elements found:</Text>
                  <Text sx={styles.fileInfoSubtitle}>106</Text>
                  <Box sx={styles.fileInfoDivider} />
                  <Text sx={styles.fileInfoTitle}>Collisions:</Text>
                  <Text sx={styles.fileInfoSubtitle}>2</Text>
                  <Box sx={styles.fileInfoDivider} />
                  <Text sx={styles.fileInfoTitle}>Source:</Text>
                  <Text sx={styles.fileInfoSubtitle}>{PROJECTS[id]?.name}</Text>
                  {selectedFile ? (
                    <Flex sx={styles.paginationWrapper}>
                      <Text sx={styles.jumpToSheetText}>Jump to sheet</Text>
                      <Box>
                        <TextField
                          wrapperProps={{
                            maxWidth: '3.021vw',
                            height: '1.875vw',
                          }}
                          value={currentSheet}
                          onChange={(e) => setCurrentSheet(e.target.value)}
                          inputType='number'
                        />
                      </Box>
                      <Text sx={styles.sheetsCountText}>
                        / {handleFileSearch(PROJECTS[id])?.sheets.length}
                      </Text>
                    </Flex>
                  ) : null}
                </Box>
              </Flex>
            </>
          ) : (
            <Videoplayer videoSource={video} />
          )}
        </Card>
      </Box>
    </Box>
  );
};

export default ProjectPage;
