import { Route, Routes } from 'react-router-dom';

import LoginPage from '../../pages/login';
import ProjectsPage from '../../pages/projects';
import ProjectPage from '../../pages/projects/[id]/project';
import AddProject from '../../pages/projects/add-project';
import ProjectSheet from '../../pages/sheet/[id]';

const Router = () => {
  return (
    <Routes>
      <Route path='/' element={<LoginPage />} />
      <Route path='projects' element={<ProjectsPage />} />
      <Route path='projects/add-project' element={<AddProject />} />
      <Route path='projects/:id' element={<ProjectPage />} />
      <Route path='projects/:id/sheet/:name' element={<ProjectSheet />} />
    </Routes>
  );
};

export default Router;
