/* eslint-disable prettier/prettier */
export const SHEETDATA = {
  sheetName: 'A151',
  dimensions: {
    width: 1066.8,
    height: 762,
  },
  sheetProperties: [
    {
      propName: 'Plug',
      propValue: '40',
    },
    {
      propName: 'Location',
      propValue: '38',
    },
    {
      propName: 'Power',
      propValue: '250w',
    },
    {
      propName: 'Sheet',
      propValue: 'A151',
    },
  ],
  elements: [
    { id: 'Receptacles', name: 'Receptacles', parentId: null, type: 'folder' },
    { id: 'Doors', name: 'Doors', parentId: null, type: 'folder' },
    { id: 'Walls', name: 'Walls', parentId: null, type: 'folder' },
    { id: 'Plumbing Fixtures', name: 'Plumbing Fixtures', parentId: null, type: 'folder' },
    { id: 'Areas', name: 'Area', parentId: null, type: 'folder' },
    { id: 'Room Names', name: 'Room Name', parentId: null, type: 'folder' },
    { id: 'Room Numbers', name: 'Room Number', parentId: null, type: 'folder' },
    { id: 'Wall Tags', name: 'Wall Tag', parentId: null, type: 'folder' },
    { id: 'Door Tags', name: 'Door Tag', parentId: null, type: 'folder' },
    { id: 'Window Elevations', name: 'Window Elevation', parentId: null, type: 'folder' },
    { id: 'Grids', name: 'Grids', parentId: null, type: 'folder' },
    { id: 'Texts', name: 'Text', parentId: null, type: 'folder' },

    {
      id: 'Door D001',
      name: 'Door D001',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '207.99552',
          left: '358.95279999999997',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D002',
      name: 'Door D002',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '207.82788',
          left: '429.41239999999993',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D003',
      name: 'Door D003',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '207.07857999999996',
          left: '498.8179',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D004',
      name: 'Door D004',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '234.40644',
          left: '477.59112',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D005',
      name: 'Door D005',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '235.91012',
          left: '438.05348000000004',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D006',
      name: 'Door D006',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '235.0643',
          left: '398.51583999999997',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D007',
      name: 'Door D007',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '235.34623999999997',
          left: '358.59974',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D008',
      name: 'Door D008',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '212.81897999999998',
          left: '286.47644',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D009',
      name: 'Door D009',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '212.04428',
          left: '241.01044',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D010',
      name: 'Door D010',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '243.27865999999997',
          left: '282.3591',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D011',
      name: 'Door D011',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '199.31125999999998',
          left: '228.72191999999998',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D012',
      name: 'Door D012',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '292.94073999999995',
          left: '238.75237999999996',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D013',
      name: 'Door D013',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '295.2877',
          left: '257.88111999999995',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D014',
      name: 'Door D014',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '377.27636',
          left: '228.85907999999998',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D015',
      name: 'Door D015',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '425.75226',
          left: '250.15952000000001',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D016',
      name: 'Door D016',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '382.80339999999995',
          left: '359.19664',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D017',
      name: 'Door D017',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '382.22682',
          left: '428.9171',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D018',
      name: 'Door D018',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '381.00762',
          left: '658.1521',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D019',
      name: 'Door D019',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '371.91949999999997',
          left: '705.5459599999999',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D020',
      name: 'Door D020',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '372.50624',
          left: '729.24416',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D021',
      name: 'Door D021',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '334.91169999999994',
          left: '805.7718199999999',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D022',
      name: 'Door D022',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '241.42954',
          left: '824.88278',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D023',
      name: 'Door D023',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '285.33852',
          left: '745.6017599999999',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D024',
      name: 'Door D024',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '323.89572',
          left: '745.0404199999999',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D025',
      name: 'Door D025',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '334.22589999999997',
          left: '656.66874',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D026',
      name: 'Door D026',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '235.11001999999996',
          left: '701.01968',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D027',
      name: 'Door D027',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '234.76966',
          left: '663.8036',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D028',
      name: 'Door D028',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '234.87633999999997',
          left: '625.9195',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D029',
      name: 'Door D029',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '207.19034',
          left: '607.57308',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D030',
      name: 'Door D030',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '191.83604',
          left: '707.91324',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D031',
      name: 'Door D031',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '334.63991999999996',
          left: '480.3902',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D032',
      name: 'Door D032',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '334.90662',
          left: '447.76643999999993',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D033',
      name: 'Door D033',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '306.65166',
          left: '412.115',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D034',
      name: 'Door D034',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '307.36031999999994',
          left: '397.94433999999995',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D035',
      name: 'Door D035',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '299.10532',
          left: '306.54244',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D036',
      name: 'Door D036',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '240.32464000000002',
          left: '732.51568',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D037',
      name: 'Door D037',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '205.70189999999997',
          left: '694.8398599999999',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D038',
      name: 'Door D038',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '164.39388',
          left: '520.93114',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D039',
      name: 'Door D039',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '280.68523999999996',
          left: '597.2175',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Door D040',
      name: 'Door D040',
      parentId: 'Doors',
      type: 'element',
      properties: {
        coords: {
          top: '305.88203999999996',
          left: '730.25',
        },
        size: {
          height: '15',
          width: '15',
        },
        type: 'door',
        info: [],
      },
    },
    {
      id: 'Receptacle 11111',
      name: 'Receptacle 11111',
      parentId: 'Receptacles',
      type: 'element',
      properties: {
        coords: {
          top: '170',
          left: '250',
        },
        size: {
          height: '10',
          width: '10',
        },
        type: 'receptacle',
        info: [
          {
            propName: 'Door',
            propValue: '14815',
          },
        ],
      },
    },
    {
      id: 'Wall 1',
      name: 'Wall 1',
      parentId: 'Walls',
      type: 'element',
      properties: {
        coords: {
          top: '100',
          left: '100',
        },
        size: {
          height: '20',
          width: '1',
        },
        type: 'wall',
        info: [
          {
            propName: 'Door',
            propValue: '14815',
          },
        ],
      },
    },
    {
      id: 'Plumbing Fixture 1',
      name: 'Plumbing Fixture 1',
      parentId: 'Plumbing Fixtures',
      type: 'element',
      properties: {
        coords: {
          top: '15',
          left: '270',
        },
        size: {
          height: '10',
          width: '10',
        },
        type: 'plumbingFixture',
        info: [
          {
            propName: 'Plumbing Fixtures',
            propValue: '14815',
          },
        ],
      },
    },
    {
      id: 'Area 1',
      name: 'Area 1',
      parentId: 'Areas',
      type: 'element',
      properties: {
        coords: {
          top: '45',
          left: '130',
        },
        size: {
          height: '10',
          width: '10',
        },
        type: 'area',
        info: [
          {
            propName: 'Door',
            propValue: '14815',
          },
        ],
      },
    },
    {
      id: 'Room Name 1',
      name: 'Room Name 1',
      parentId: 'Room Names',
      type: 'element',
      properties: {
        coords: {
          top: '210',
          left: '100',
        },
        size: {
          height: '10',
          width: '10',
        },
        type: 'roomName',
        info: [
          {
            propName: 'Door',
            propValue: '14815',
          },
        ],
      },
    },
    {
      id: 'Room Number 1',
      name: 'Room Number 1',
      parentId: 'Room Numbers',
      type: 'element',
      properties: {
        coords: {
          top: '90',
          left: '90',
        },
        size: {
          height: '10',
          width: '10',
        },
        type: 'roomNumber',
        info: [
          {
            propName: 'Door',
            propValue: '14815',
          },
        ],
      },
    },
    {
      id: 'Wall Tag 1',
      name: 'Wall Tag 1',
      parentId: 'Wall Tags',
      type: 'element',
      properties: {
        coords: {
          top: '350',
          left: '210',
        },
        size: {
          height: '10',
          width: '10',
        },
        type: 'wallTag',
        info: [
          {
            propName: 'Door',
            propValue: '14815',
          },
        ],
      },
    },
    {
      id: 'Door Tag 1',
      name: 'Door Tag 1',
      parentId: 'Door Tags',
      type: 'element',
      properties: {
        coords: {
          top: '90',
          left: '260',
        },
        size: {
          height: '10',
          width: '10',
        },
        type: 'doorTag',
        info: [
          {
            propName: 'Door Tag',
            propValue: '14815',
          },
        ],
      },
    },
    {
      id: 'Window Elevation 1',
      name: 'Window Elevation 1',
      parentId: 'Window Elevations',
      type: 'element',
      properties: {
        coords: {
          top: '10',
          left: '156',
        },
        size: {
          height: '10',
          width: '10',
        },
        type: 'windowElevation',
        info: [
          {
            propName: 'Door',
            propValue: '14815',
          },
        ],
      },
    },
    {
      id: 'Grid 1',
      name: 'Grid 1',
      parentId: 'Grids',
      type: 'element',
      properties: {
        coords: {
          top: '39',
          left: '33.9',
        },
        size: {
          height: '15',
          width: '1',
        },
        type: 'grid',
        info: [
          {
            propName: 'Door',
            propValue: '14815',
          },
        ],
      },
    },
    {
      id: 'Text 1',
      name: 'Text 1',
      parentId: 'Texts',
      type: 'element',
      properties: {
        coords: {
          top: '78',
          left: '25.9',
        },
        size: {
          height: '10',
          width: '10',
        },
        type: 'text',
        info: [
          {
            propName: 'Door',
            propValue: '14815',
          },
        ],
      },
    },
  ],
};
