import { Box, Button, Flex, Progress, SimpleGrid, Text } from '@chakra-ui/react';
import React from 'react';
import { scroller } from 'react-scroll';

import DownloadIcon from '../../../assets/icons/DownloadIcon';
import FilePlusIcon from '../../../assets/icons/FilePlusIcon';
import GearListIcon from '../../../assets/icons/GearList';
import NewspaperIcon from '../../../assets/icons/NewspaperIcon';
import TextField from '../../../components/text-field';
import { PROJECT_DATA } from '../../../mock-data';
import { SheetCard } from '../[id]/components/sheet-card';
import { styles } from './styles';

export const FileContent = ({ fileData, selectedFile }) => {
  const [progressValue, setProgressValue] = React.useState(0);
  const [currentSheet, setCurrentSheet] = React.useState(1);

  React.useEffect(() => {
    if (selectedFile) {
      scroller.scrollTo(`sheet${currentSheet}`, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'scroll-container',
      });
    }
  }, [currentSheet, selectedFile]);

  React.useEffect(() => {
    if (progressValue <= 95) {
      setTimeout(
        () => setProgressValue(progressValue + 1),
        Math.floor(Math.random() * (35 - 1 + 1)) + 1,
      );
    }
    if (progressValue > 95) {
      setProgressValue(100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressValue]);

  const handleFileSearch = (projectData) => {
    const query = selectedFile?.split('/');
    let arrayDepth = 0;
    if (selectedFile) {
      return projectData?.folders
        ?.map((projectFolder) => {
          arrayDepth += 1;
          if (projectFolder?.folderName !== query[0]) {
            return handleFileSearch(projectFolder);
          }
          if (projectFolder?.folderName === query[0]) {
            return projectFolder?.files.find((folderFile) => folderFile?.fileName === query[1]);
          }
          return null;
        })
        .flat(arrayDepth)
        .find((item) => item !== undefined);
    }
    return null;
  };

  return (
    <>
      {progressValue === 100 ? (
        <Flex padding='1.667vw 0.938vw 0.938vw 0.938vw' flexDirection='column'>
          <Flex sx={styles.headingWrapper}>
            <Text sx={styles.projectNameText}>
              Blockbuster Mall - Floor plan - rev 20220612.pdf
            </Text>
            <Flex>
              <Button variant='borderless' sx={styles.organizeProjectBtn}>
                <Flex alignItems='center'>
                  <GearListIcon />
                  <Text ml='0.313vw'>Organize Project</Text>
                </Flex>
              </Button>
              <Button variant='borderless' sx={styles.downloadBtn}>
                <Flex alignItems='center'>
                  <DownloadIcon />
                  <Text ml='0.313vw'>Download</Text>
                </Flex>
              </Button>
            </Flex>
          </Flex>
          <Flex sx={styles.infoBlocksWrapper}>
            <Box sx={styles.sheetListWrapper}>
              <Box sx={styles.sheetListInnerWrapper} id='scroll-container'>
                <SimpleGrid columns='4' spacingX='1.8vw' spacingY='1.250vw' w='99%'>
                  {handleFileSearch(PROJECT_DATA)?.sheets?.map((sheetData, idx) => (
                    <Box key={idx} id={`sheet${idx + 1}`}>
                      <SheetCard sheedtData={sheetData} index={idx} />
                    </Box>
                  ))}
                </SimpleGrid>
              </Box>
            </Box>
            <Box sx={styles.fileInfoWrapper}>
              <Text sx={styles.fileInfoTitle}>Category:</Text>
              <Text sx={styles.fileInfoSubtitle}>Electric</Text>
              <Box sx={styles.fileInfoDivider} />
              <Text sx={styles.fileInfoTitle}>Sheets:</Text>
              <Text sx={styles.fileInfoSubtitle}>1251</Text>
              <Box sx={styles.fileInfoDivider} />
              <Text sx={styles.fileInfoTitle}>Processing date:</Text>
              <Text sx={styles.fileInfoSubtitle}>06/12/2022</Text>
              <Box sx={styles.fileInfoDivider} />
              <Text sx={styles.fileInfoTitle}>Elements found:</Text>
              <Text sx={styles.fileInfoSubtitle}>106</Text>
              <Box sx={styles.fileInfoDivider} />
              <Text sx={styles.fileInfoTitle}>Collisions:</Text>
              <Text sx={styles.fileInfoSubtitle}>2</Text>
              <Box sx={styles.fileInfoDivider} />
              <Text sx={styles.fileInfoTitle}>Source:</Text>
              <Text sx={styles.fileInfoSubtitle}>Blockbuster Mall - Floor plan...</Text>
              {selectedFile ? (
                <Flex sx={styles.paginationWrapper}>
                  <Text sx={styles.jumpToSheetText}>Jump to sheet</Text>
                  <Box>
                    <TextField
                      wrapperProps={{
                        maxWidth: '3.021vw',
                        height: '1.875vw',
                      }}
                      value={currentSheet}
                      onChange={(e) => setCurrentSheet(e.target.value)}
                      inputType='number'
                    />
                  </Box>
                  <Text sx={styles.sheetsCountText}>
                    / {handleFileSearch(PROJECT_DATA)?.sheets.length}
                  </Text>
                </Flex>
              ) : null}
            </Box>
          </Flex>
        </Flex>
      ) : (
        <Flex sx={styles.filePreviewWrapper}>
          <Flex justifyContent='space-between'>
            <Text sx={styles.previewFileSizeText}>Project name</Text>
            <Flex alignItems='center'>
              <FilePlusIcon height='1.042vw' width='1.042vw' />
              <Text sx={styles.previewFileCountText}>3 files</Text>
            </Flex>
          </Flex>
          <Flex justifyContent='space-between' mt='0.2vw'>
            <Text sx={styles.previewFileNameText}>{fileData?.fileName}</Text>
            <Text sx={styles.previewFileSizeText}>12.0 mb</Text>
          </Flex>
          <Box sx={styles.previewFileDivider} />
          <Flex alignSelf='center' flexDirection='column' alignItems='center'>
            <NewspaperIcon />
            <Text sx={styles.previewNotAvailableText}>Preview not available yet.</Text>
            <Text sx={styles.previewUpdatingText}>Updating</Text>
            <Flex alignItems='center'>
              <Progress value={progressValue} sx={styles.previewProgress} />
              <Text sx={styles.previewProgressValueText}>{`${progressValue}%`}</Text>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};
