/* eslint-disable prettier/prettier */
import s1 from '../assets/images/13-1.png';
import s2 from '../assets/images/16-1.png';
import s3 from '../assets/images/27-1.png';
import s4 from '../assets/images/39-1.png';
import s5 from '../assets/images/56-1.png';
import s6 from '../assets/images/61-1.png';
import s7 from '../assets/images/64-1.png';
import s8 from '../assets/images/66-1.png';
import s9 from '../assets/images/69-1.png';
import s10 from '../assets/images/71-1.png';
import s11 from '../assets/images/72-1.png';
import s12 from '../assets/images/75-1.png';
import s13 from '../assets/images/78-1.png';
import s14 from '../assets/images/79-1.png';
import s15 from '../assets/images/80-1.png';
import s16 from '../assets/images/89-1.png';
import s17 from '../assets/images/93-1.png';
import s18 from '../assets/images/97-1.png';
import s19 from '../assets/images/98-1.png';
import s20 from '../assets/images/108-1.png';
import s21 from '../assets/images/113-1.png';
import s22 from '../assets/images/116-1.png';
import s23 from '../assets/images/120-1.png';
import s24 from '../assets/images/123-1.png';
import s25 from '../assets/images/125-1.png';
import s26 from '../assets/images/148-1.png';
import s27 from '../assets/images/149-1.png';
import s28 from '../assets/images/151-1.png';
import p1 from '../assets/images/p1.jpeg';
import p2 from '../assets/images/p2.jpeg';
import p3 from '../assets/images/p3.jpeg';
import p4 from '../assets/images/p4.jpeg';
import p5 from '../assets/images/p5.jpeg';
import p6 from '../assets/images/p6.jpeg';
import p7 from '../assets/images/p7.jpeg';
import p8 from '../assets/images/p8.jpeg';
import p9 from '../assets/images/p9.jpeg';

export const PROJECTS = [
  {
    id: 1,
    name: 'The Flax Residence',
    address: 'Fall City, Washington(WA), 98024',
    clientName: 'CBConstruction LLC',
    creationDate: '09.08.2022, 1:34pm',
    previewImage: p1,
    filesCount: '123',
    size: '125.3 Mb',
    folders: [
      {
        folderName: 'Source files',
        depthLevel: 0,
        files: null,
        folders: [
          {
            folderName: 'Drawings',
            depthLevel: 1,
            files: [
              {
                fileName: 'The Flax Residence',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Models',
            depthLevel: 1,
            files: [
              {
                fileName: 'The Flax Residence',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Rules',
            depthLevel: 1,
            files: [
              {
                fileName: 'The Flax Residence',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
        ],
      },
      { folderName: 'Drawing index', depthLevel: 0, files: null, folders: null },
      { folderName: 'Elements', depthLevel: 0, files: null, folders: null },
    ],
  },
  {
    id: 2,
    name: 'Hotel Del Quesadilla',
    address: 'New Salem Rd luka, Mississipi(MS), 38852',
    clientName: 'ABConsulting INC',
    creationDate: '08.01.2022, 2:36am',
    previewImage: p2,
    filesCount: '147',
    size: '185.3 Mb',
    folders: [
      {
        folderName: 'Source files',
        depthLevel: 0,
        files: null,
        folders: [
          {
            folderName: 'Drawings',
            depthLevel: 1,
            files: [
              {
                fileName: 'Hotel Del Quesadilla',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Models',
            depthLevel: 1,
            files: [
              {
                fileName: 'Hotel Del Quesadilla',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Rules',
            depthLevel: 1,
            files: [
              {
                fileName: 'Hotel Del Quesadilla',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
        ],
      },
      { folderName: 'Drawing index', depthLevel: 0, files: null, folders: null },
      { folderName: 'Elements', depthLevel: 0, files: null, folders: null },
    ],
  },
  {
    id: 3,
    name: 'St. Rose Philippine Hospital',
    address: '8820 Sylvania St Lorton, Virginia(VA), 22079',
    clientName: 'ENGConsulting LLC',
    creationDate: '06.05.2022, 3.16am',
    previewImage: p3,
    filesCount: '141',
    size: '137.7 Mb',
    folders: [
      {
        folderName: 'Source files',
        depthLevel: 0,
        files: null,
        folders: [
          {
            folderName: 'Drawings',
            depthLevel: 1,
            files: [
              {
                fileName: 'St. Rose Philippine Hospital',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Models',
            depthLevel: 1,
            files: [
              {
                fileName: 'St. Rose Philippine Hospital',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Rules',
            depthLevel: 1,
            files: [
              {
                fileName: 'St. Rose Philippine Hospital',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
        ],
      },
      { folderName: 'Drawing index', depthLevel: 0, files: null, folders: null },
      { folderName: 'Elements', depthLevel: 0, files: null, folders: null },
    ],
  },
  {
    id: 4,
    name: 'St. Catherine High School',
    address: '106 Roberts Dr Mount Vernon, Missouri(MO), 65712',
    clientName: 'HPHContractors',
    creationDate: '02.03.2022, 4:16pm',
    previewImage: p4,
    filesCount: '101',
    size: '132.5 Mb',
    folders: [
      {
        folderName: 'Source files',
        depthLevel: 0,
        files: null,
        folders: [
          {
            folderName: 'Drawings',
            depthLevel: 1,
            files: [
              {
                fileName: 'St. Catherine High School',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Models',
            depthLevel: 1,
            files: [
              {
                fileName: 'St. Catherine High School',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Rules',
            depthLevel: 1,
            files: [
              {
                fileName: 'St. Catherine High School',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
        ],
      },
      { folderName: 'Drawing index', depthLevel: 0, files: null, folders: null },
      { folderName: 'Elements', depthLevel: 0, files: null, folders: null },
    ],
  },
  {
    id: 5,
    name: 'NGH Clinic',
    address: '268 Tidewater Cir Preston, Maryland(MD), 21655',
    clientName: 'MBBuilders INC',
    creationDate: '12.03.2021, 1:16pm',
    previewImage: p5,
    filesCount: '71',
    size: '102.2 Mb',
    folders: [
      {
        folderName: 'Source files',
        depthLevel: 0,
        files: null,
        folders: [
          {
            folderName: 'Drawings',
            depthLevel: 1,
            files: [
              {
                fileName: 'NGH Clinic',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Models',
            depthLevel: 1,
            files: [
              {
                fileName: 'NGH Clinic',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Rules',
            depthLevel: 1,
            files: [
              {
                fileName: 'NGH Clinic',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
        ],
      },
      { folderName: 'Drawing index', depthLevel: 0, files: null, folders: null },
      { folderName: 'Elements', depthLevel: 0, files: null, folders: null },
    ],
  },
  {
    id: 6,
    name: 'HH Distribution Center',
    address: 'Old Hancock Rd Hancock, New Hampshire(NH), 03449',
    clientName: 'BBMEP Consulting LLC',
    creationDate: '11.05.2021, 5:16pm',
    previewImage: p6,
    filesCount: '255',
    size: '752.2 Mb',
    folders: [
      {
        folderName: 'Source files',
        depthLevel: 0,
        files: null,
        folders: [
          {
            folderName: 'Drawings',
            depthLevel: 1,
            files: [
              {
                fileName: 'HH Distribution Center',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Models',
            depthLevel: 1,
            files: [
              {
                fileName: 'HH Distribution Center',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Rules',
            depthLevel: 1,
            files: [
              {
                fileName: 'HH Distribution Center',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
        ],
      },
      { folderName: 'Drawing index', depthLevel: 0, files: null, folders: null },
      { folderName: 'Elements', depthLevel: 0, files: null, folders: null },
    ],
  },
  {
    id: 7,
    name: 'MELTA HQ Office',
    address: '268 Tidewater Cir Preston, Maryland(MD), 21655',
    clientName: 'FireStrong Consulting LLC',
    creationDate: '12.01.2022, 4:14pm',
    previewImage: p7,
    filesCount: '185',
    size: '452.2 Mb',
    folders: [
      {
        folderName: 'Source files',
        depthLevel: 0,
        files: null,
        folders: [
          {
            folderName: 'Drawings',
            depthLevel: 1,
            files: [
              {
                fileName: 'MELTA HQ Office',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Models',
            depthLevel: 1,
            files: [
              {
                fileName: 'MELTA HQ Office',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Rules',
            depthLevel: 1,
            files: [
              {
                fileName: 'MELTA HQ Office',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
        ],
      },
      { folderName: 'Drawing index', depthLevel: 0, files: null, folders: null },
      { folderName: 'Elements', depthLevel: 0, files: null, folders: null },
    ],
  },
  {
    id: 8,
    name: 'HPX Warehouse',
    address: 'Berkeley Dr Vernon Rockville, Connecticut(CT), 06066',
    clientName: 'KLJ Construction',
    creationDate: '10.03.2022, 3:14pm',
    previewImage: p8,
    filesCount: '115',
    size: '312.2 Mb',
    folders: [
      {
        folderName: 'Source files',
        depthLevel: 0,
        files: null,
        folders: [
          {
            folderName: 'Drawings',
            depthLevel: 1,
            files: [
              {
                fileName: 'HPX Warehouse',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Models',
            depthLevel: 1,
            files: [
              {
                fileName: 'HPX Warehouse',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Rules',
            depthLevel: 1,
            files: [
              {
                fileName: 'HPX Warehouse',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
        ],
      },
      { folderName: 'Drawing index', depthLevel: 0, files: null, folders: null },
      { folderName: 'Elements', depthLevel: 0, files: null, folders: null },
    ],
  },
  {
    id: 9,
    name: 'BGH Hospital',
    address: '2323 W Kentucky St LouisVille, Kentucky(KY), 40210',
    clientName: 'Higher Contractors',
    creationDate: '06.05.2020, 4:14pm',
    previewImage: p9,
    filesCount: '105',
    size: '112.2 Mb',
    folders: [
      {
        folderName: 'Source files',
        depthLevel: 0,
        files: null,
        folders: [
          {
            folderName: 'Drawings',
            depthLevel: 1,
            files: [
              {
                fileName: 'BGH Hospital',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Models',
            depthLevel: 1,
            files: [
              {
                fileName: 'BGH Hospital',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
          {
            folderName: 'Rules',
            depthLevel: 1,
            files: [
              {
                fileName: 'BGH Hospital',
                depthLevel: 2,
                processing: true,
                sheets: [
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s1,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s2,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s3,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s4,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s5,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s6,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s7,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s8,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s9,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s10,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s11,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s12,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s13,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s14,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s15,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s16,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s17,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s18,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s19,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s20,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Bathroom',
                    previewImage: s21,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan v3',
                    previewImage: s22,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s23,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan',
                    previewImage: s24,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan rev2',
                    previewImage: s25,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall EDITED',
                    previewImage: s26,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Electrical plan Hall',
                    previewImage: s27,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                  {
                    sheetName: 'Software v7.0',
                    previewImage: s28,
                    sheetType: 'Electrics',
                    latestRevision: '106.1',
                    pageSize: '12 580 x 16 650 px',
                    scale: '15%',
                    uploadDate: '06/12/2022',
                    uploadedBy: 'Username',
                  },
                ],
              },
            ],
            folders: null,
          },
        ],
      },
      { folderName: 'Drawing index', depthLevel: 0, files: null, folders: null },
      { folderName: 'Elements', depthLevel: 0, files: null, folders: null },
    ],
  },
];
