import React from 'react';

const InfoIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='0.833vw'
      height='0.833vw'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill='#fff'
        d='M8 14.667A6.667 6.667 0 118 1.333a6.667 6.667 0 010 13.334zm0-1.334A5.334 5.334 0 108 2.666a5.334 5.334 0 000 10.667zm-.667-8.666h1.334V6H7.333V4.667zm0 2.666h1.334v4H7.333v-4z'
      ></path>
    </svg>
  );
};

export default InfoIcon;
