export const styles = {
  projectsWrapper: {
    paddingTop: '0.625vw',
    paddingBottom: '0.938vw',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  projectsHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  projectsHeading: {
    color: 'gray.400',
    fontSize: '1.250vw',
    fontWeight: 600,
  },
  rightBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  sortbyText: { color: 'gray.300', fontSize: '0.729vw', marginRight: '0.417vw' },
  sortMenuBtn: {
    color: 'gray.400',
    fontSize: '0.729vw',
    height: '1.250vw',
    maxWidth: '6.250vw',
    minWidth: '6.250vw',
    alignItems: 'center',
    cursor: 'pointer',
    mt: '0.1vw',
  },
  sortMenuList: {
    minWidth: '6.250vw',
    border: '0.052vw #6E6B7B solid',
    borderRadius: '0.3vw',
    paddingY: '0.2vw',
    overflow: 'hidden',
  },
  sortMenuItem: {
    fontSize: '0.729vw',
    maxheight: '1.250vw',
  },
  addProjectBtn: {
    width: '6.979vw',
    marginLeft: '2.083vw',
    fontSize: '0.729vw',
  },
  projectCardWrapper: {
    width: '13.542vw',
    border: '0.052vw solid #7368F0',
    borderRadius: '0.625vw',
    padding: '0.833vw 1.250vw 1.250vw 1.250vw',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  cardMenuBtn: {
    cursor: 'pointer',
  },
  projectCardMenuList: {
    minWidth: '10.052vw',
    borderRadius: '0.313vw',
    border: 'none',
    paddingY: '0.833vw',
    boxShadow: '0 0.208vw 1.250vw rgba(34, 41, 47, 0.15)',
    display: 'flex',
    flexDirection: 'column',
  },
  projectCardMenuItem: {
    fontSize: '0.729vw',
    paddingX: '1.042vw',
    paddingY: '0',
    fontWeight: 600,
    color: 'black',
    maxHeight: '1.042vw',
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    height: '0.052vw',
    width: '7.969vw',
    alignSelf: 'center',
    background: '#F3F3F3',
    marginY: '0.833vw',
  },
  cardMenuWrapper: {
    position: 'absolute',
    right: '0.625vw',
  },
  cardCoverWrapper: {
    width: '8.333vw',
    height: '8.333vw',
    border: '0.052vw solid #F3F3F3',
    borderRadius: '0.313vw',
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  cardCoverImage: {
    objectFit: 'cover',
    width: 'inherit',
    height: 'inherit',
    cursor: 'pointer',
  },
  projectNameText: {
    fontSize: '0.833vw',
    fontWeight: 600,
    lineHeight: '140%',
    color: 'purple.100',
    marginTop: '0.833vw',
    marginBottom: '1.042vw',
    cursor: 'pointer',
  },
  cardInfoText: {
    fontSize: '0.729vw',
    color: 'gray.400',
    lineHeight: '160%',
    marginLeft: '0.417vw',
    fontWeight: 600,
    cursor: 'pointer',
  },
  filesInfoWrapper: {
    justifyContent: 'space-between',
    marginTop: '1.458vw',
  },
  filesInfoText: {
    fontSize: '0.729vw',
    lineHeight: '160%',
    color: 'gray.200',
    cursor: 'pointer',
  },
  gridWrapper: {
    overflowY: 'auto',
    height: '100%',
    width: '100%',
    paddingRight: '1vw',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.313vw',
    },
    '&::-webkit-scrollbar-track': {
      width: '0.313vw',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#6E6B7B',
      borderRadius: '1.250vw',
    },
  },
};
