const EditIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1.042vw'
      height='1.042vw'
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        fill='#000'
        d='M4.167 15.833h1.178l7.762-7.761-1.179-1.179-7.761 7.762v1.178zM17.5 17.5h-15v-3.536L13.696 2.768a.833.833 0 011.178 0l2.358 2.358a.833.833 0 010 1.178l-9.53 9.53H17.5V17.5zM13.107 5.715l1.178 1.178 1.178-1.178-1.178-1.178-1.178 1.178z'
      ></path>
    </svg>
  );
};

export default EditIcon;
