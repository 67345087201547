import React from 'react';

const PencilIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='0.833vw'
      height='0.833vw'
      fill='none'
      viewBox='0 0 16 17'
    >
      <path fill='#1E1E1E' d='M0 0H16V17H0z'></path>
      <path fill='#F8F8F8' d='M0 0H1920V980H0z' transform='translate(-616 -713)'></path>
      <g filter='url(#filter0_d_96_1571)'>
        <rect width='1562' height='798' x='-276' y='-549' fill='#fff' rx='6'></rect>
        <g filter='url(#filter1_d_96_1571)'>
          <rect width='1442' height='80' x='-216' y='-107' fill='#fff' rx='6'></rect>
        </g>
        <g filter='url(#filter2_d_96_1571)'>
          <rect width='1442' height='80' x='-216' y='-15' fill='#fff' rx='6'></rect>
          <path
            fill='#000'
            d='M8.6 5.072l2.828 2.829-6.6 6.6H2v-2.83l6.6-6.6v.001zm.943-.943l1.414-1.414a.667.667 0 01.942 0L13.785 4.6a.667.667 0 010 .942l-1.414 1.414L9.543 4.13z'
          ></path>
        </g>
      </g>
      <defs>
        <filter
          id='filter0_d_96_1571'
          width='1610'
          height='846'
          x='-300'
          y='-569'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset dy='4'></feOffset>
          <feGaussianBlur stdDeviation='12'></feGaussianBlur>
          <feColorMatrix values='0 0 0 0 0.133333 0 0 0 0 0.160784 0 0 0 0 0.184314 0 0 0 0.1 0'></feColorMatrix>
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_96_1571'></feBlend>
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_96_1571' result='shape'></feBlend>
        </filter>
        <filter
          id='filter1_d_96_1571'
          width='1490'
          height='128'
          x='-240'
          y='-127'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset dy='4'></feOffset>
          <feGaussianBlur stdDeviation='12'></feGaussianBlur>
          <feColorMatrix values='0 0 0 0 0.133333 0 0 0 0 0.160784 0 0 0 0 0.184314 0 0 0 0.1 0'></feColorMatrix>
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_96_1571'></feBlend>
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_96_1571' result='shape'></feBlend>
        </filter>
        <filter
          id='filter2_d_96_1571'
          width='1490'
          height='128'
          x='-240'
          y='-35'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset dy='4'></feOffset>
          <feGaussianBlur stdDeviation='12'></feGaussianBlur>
          <feColorMatrix values='0 0 0 0 0.133333 0 0 0 0 0.160784 0 0 0 0 0.184314 0 0 0 0.1 0'></feColorMatrix>
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_96_1571'></feBlend>
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_96_1571' result='shape'></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default PencilIcon;
