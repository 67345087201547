import { Box, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import AddressIcon from '../../../../assets/icons/AddressIcon';
import FileListIcon from '../../../../assets/icons/FileListIcon';
import PaperSheetIcon from '../../../../assets/icons/PaperSheetIcon';
import { cropText } from '../../../../utils';
import { styles } from './styles';

export const SheetCard = ({ sheedtData, index }) => {
  const navigate = useNavigate();
  return (
    <Flex key={index} sx={styles.sheetCardWrapper}>
      <Flex sx={styles.sheetCardImageWrapper}>
        {sheedtData.previewImage ? (
          <Image src={sheedtData.previewImage} alt='previewImage' sx={styles.sheetCardImage} />
        ) : (
          <FileListIcon fill='#7368F0' size='4.271vw' />
        )}
      </Flex>
      <Text sx={styles.sheetCardNameText} onClick={() => navigate('sheet/1')}>
        {cropText(sheedtData.sheetName, 25)}
      </Text>
      <Flex justifyContent='space-between' w='100%'>
        <Flex alignItems='center'>
          <AddressIcon />
          <Text sx={styles.sheetCardTypeText}>{sheedtData.sheetType}</Text>
        </Flex>
        <Flex alignItems='center'>
          <PaperSheetIcon />
          <Text sx={styles.sheetCardTypeText}>{index + 1}</Text>
        </Flex>
      </Flex>
      <Flex sx={styles.expandableBlock}>
        <Flex justifyContent='space-between'>
          <Text sx={styles.sheetAttributeNameText}>Latest revision:</Text>
          <Text sx={styles.sheetAttributeValueText}>{sheedtData.latestRevision}</Text>
        </Flex>
        <Box sx={styles.sheetCardDivider} />
        <Flex justifyContent='space-between'>
          <Text sx={styles.sheetAttributeNameText}>Page size:</Text>
          <Text sx={styles.sheetAttributeValueText}>{sheedtData.pageSize}</Text>
        </Flex>
        <Box sx={styles.sheetCardDivider} />
        <Flex justifyContent='space-between'>
          <Text sx={styles.sheetAttributeNameText}>Scale:</Text>
          <Text sx={styles.sheetAttributeValueText}>{sheedtData.scale}</Text>
        </Flex>
        <Box sx={styles.sheetCardDivider} />
        <Flex justifyContent='space-between'>
          <Text sx={styles.sheetAttributeNameText}>Upload date:</Text>
          <Text sx={styles.sheetAttributeValueText}>{sheedtData.uploadDate}</Text>
        </Flex>
        <Box sx={styles.sheetCardDivider} />
        <Flex justifyContent='space-between'>
          <Text sx={styles.sheetAttributeNameText}>Uploaded by:</Text>
          <Text sx={styles.sheetAttributeValueText}>{sheedtData.uploadedBy}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
