/* eslint-disable no-nested-ternary */
export const styles = {
  customCheckbox: (isSelected, variant) => ({
    border: isSelected ? 'unset' : '0.08vw solid black',
    background:
      variant === 'inverted'
        ? isSelected
          ? '#fff'
          : 'default'
        : isSelected
        ? 'purple.100'
        : 'default',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10%',
    height: '0.625vw',
    width: '0.625vw',
    cursor: 'pointer',
  }),
};
