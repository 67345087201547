import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';

import Layout from './components/layout';
import Router from './components/router';
import theme from './theme/theme';

const App = () => {
  return (
    <BrowserRouter>
      <ChakraProvider theme={extendTheme({ ...theme })}>
        <Layout>
          <Router />
        </Layout>
      </ChakraProvider>
    </BrowserRouter>
  );
};

export default App;
