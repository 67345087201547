import React from 'react';

const ListCheckIcon = ({ fill }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1.042vw'
      height='1.042vw'
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        fill={fill}
        d='M9.167 3.333H17.5V5H9.167V3.333zm0 3.334h5v1.666h-5V6.667zm0 5H17.5v1.666H9.167v-1.666zm0 3.333h5v1.667h-5V15zM2.5 3.333h5v5h-5v-5zM4.167 5v1.667h1.666V5H4.167zM2.5 11.667h5v5h-5v-5zm1.667 1.666V15h1.666v-1.667H4.167z'
      ></path>
    </svg>
  );
};

export default ListCheckIcon;
