import React from 'react';

const ArrowDownIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='0.833vw'
      height='0.833vw'
      fill='none'
      viewBox='0 0 20 21'
    >
      <path
        fill='#000'
        d='M10 11.477L5.875 7.352 4.697 8.53 10 13.834l5.303-5.304-1.178-1.178L10 11.477z'
      ></path>
    </svg>
  );
};

export default ArrowDownIcon;
