import { Box, Flex, Image, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import AddCircleIcon from '../../../assets/icons/AddCircleIcon';
import AddressIcon from '../../../assets/icons/AddressIcon';
import FolderOutlineIcon from '../../../assets/icons/FolderOutlineIcon';
import UserIcon from '../../../assets/icons/UserIcon';
import { cropText } from '../../../utils';
import { styles } from '../styles';
import { ProjectCardMenu } from './project-card-menu';

const ProjectCard = ({ projectData }) => {
  const navigate = useNavigate();
  const handleLinkClick = (projectId) => {
    navigate(`/projects/${projectId}`);
  };
  return (
    <Box sx={styles.projectCardWrapper}>
      <Box sx={styles.cardMenuWrapper}>
        <ProjectCardMenu />
      </Box>
      <Box sx={styles.cardCoverWrapper}>
        {projectData?.previewImage ? (
          <Image
            src={projectData?.previewImage}
            alt='cover'
            sx={styles.cardCoverImage}
            onClick={handleLinkClick}
          />
        ) : (
          <FolderOutlineIcon />
        )}
      </Box>
      <Text sx={styles.projectNameText} onClick={() => handleLinkClick(projectData?.id)}>
        {cropText(projectData?.name, 25)}
      </Text>
      <VStack spacing='0.417vw' align='flex-start'>
        <Flex>
          <AddressIcon />
          <Text sx={styles.cardInfoText} onClick={() => handleLinkClick(projectData?.id)}>
            {cropText(projectData?.address, 20)}
          </Text>
        </Flex>
        <Flex>
          <UserIcon />
          <Text sx={styles.cardInfoText} onClick={() => handleLinkClick(projectData?.id)}>
            {cropText(projectData?.clientName, 21)}
          </Text>
        </Flex>
        <Flex>
          <AddCircleIcon />
          <Text sx={styles.cardInfoText} onClick={() => handleLinkClick(projectData?.id)}>
            {projectData?.creationDate}
          </Text>
        </Flex>
      </VStack>
      <Flex sx={styles.filesInfoWrapper}>
        <Text
          sx={styles.filesInfoText}
          onClick={() => handleLinkClick(projectData?.id)}
        >{`${projectData?.filesCount} files`}</Text>
        <Text sx={styles.filesInfoText} onClick={() => handleLinkClick(projectData?.id)}>
          {projectData?.size}
        </Text>
      </Flex>
    </Box>
  );
};
export default ProjectCard;
