export const colors = {
  gray: {
    50: '#ECECEC',
    100: '#F8F8F8',
    200: '#B9B9C3',
    300: '#C0BDCA',
    400: '#6E6B7B',
    500: '#625F6E',
  },
  purple: {
    100: '#7367f0',
  },
  green: {
    100: '#28c76f',
  },
  blue: {
    100: '#00cfe8',
  },
  orange: {
    100: '#ff9f43',
  },
  red: {
    100: '#ea5455',
  },
};
