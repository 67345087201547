export const styles = {
  sheetCardWrapper: {
    width: '13.542vw',
    height: '15.1vw',
    borderRadius: '0.625vw',
    border: '0.052vw solid #F3F3F3',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1.250vw 1.250vw 0.833vw 1.250vw',
    position: 'relative',
    overflow: 'hidden',
    marginBottom: '0.1vw',
    _hover: {
      borderColor: 'purple.100',
      height: '25.469vw',
      zIndex: 1,
      background: 'white',
      boxShadow: '0vw 0.208vw 1.250vw rgba(34, 41, 47, 0.1)',
      transition: '0.1s',
      position: 'absolute',
    },
  },
  expandableBlock: {
    flexDirection: 'column',
    marginTop: '1.042vw',
  },
  sheetCardImageWrapper: {
    minWidth: '11.042vw',
    minHeight: '8.333vw',
    width: '11.042vw',
    height: '8.333vw',
    borderRadius: '0.313vw',
    marginBottom: '0.833vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  sheetCardImage: {
    minHeight: 'inherit',
    minWidth: 'inherit',
    height: 'inherit',
    width: 'inherit',
    objectFit: 'cover',
    borderRadius: 'inherit',
    border: '0.052vw solid rgba(0, 0, 0, 0.1)',
  },
  sheetCardNameText: {
    fontSize: '0.833vw',
    fontWeight: 600,
    lineHeight: '140%',
    color: 'purple.100',
    alignSelf: 'flex-start',
    marginBottom: '1.042vw',
    cursor: 'pointer',
  },
  sheetCardTypeText: {
    fontWeight: 600,
    fontSize: '0.729vw',
    lineHeight: '160%',
    color: 'gray.500',
    marginLeft: '0.417vw',
  },
  sheetCardDivider: {
    width: '11.042vw',
    background: '#ECECEC',
    height: '0.052vw',
    marginY: '0.417vw',
  },
  sheetAttributeNameText: {
    lineHeight: '160%',
    fontSize: '0.729vw',
    color: 'gray.500',
  },
  sheetAttributeValueText: {
    lineHeight: '160%',
    fontSize: '0.729vw',
    fontWeight: 600,
  },
};
