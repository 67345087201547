const FolderOutlineIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='4.271vw'
      height='4.271vw'
      fill='none'
      viewBox='0 0 82 82'
    >
      <path
        fill='#7368F0'
        d='M42.414 17.083H71.75a3.417 3.417 0 013.417 3.417v47.833a3.417 3.417 0 01-3.417 3.417h-61.5a3.417 3.417 0 01-3.417-3.417V13.667a3.417 3.417 0 013.417-3.417H35.58l6.833 6.833zm-28.747 6.834v41h54.666v-41H13.667z'
      ></path>
    </svg>
  );
};

export default FolderOutlineIcon;
