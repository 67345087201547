export const Accordion = {
  baseStyle: {
    root: {
      padding: 0,
      margin: 0,
      width: '100%',
      border: 'none',
      borderTopWidth: '0vw',
      _last: {
        borderBottomWidth: '0vw',
      },
    },
    container: {
      border: 'none',
    },
    icon: {
      fontSize: '1.042vw',
      padding: 0,
      margin: 0,
    },
    panel: {
      fontSize: '0.729vw',
      fontWeight: 600,
      padding: 0,
      margin: 0,
    },
    button: {
      fontSize: '0.729vw',
      fontWeight: 600,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 0,
      //   marginY: '0.313vw',
      marginX: 0,
    },
  },
};
