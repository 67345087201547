export const styles = {
  projectsWrapper: {
    paddingTop: '0.625vw',
    paddingBottom: '0.938vw',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  projectsHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  projectsHeading: {
    color: 'gray.400',
    fontSize: '1.250vw',
    fontWeight: 600,
  },
  rightBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    height: '0.052vw',
    width: '14.271vw',
    alignSelf: 'center',
    background: '#ECECEC',
    marginY: '0.625vw',
  },
  newProjectText: {
    fontSize: '0.833vw',
    fontWeight: 600,
    lineHeight: '140%',
    color: 'purple.100',
  },
  cancelBtn: {
    marginLeft: '2.083vw',
    width: '7.292vw',
    color: '#625F6E',
    fontSize: '0.729vw',
  },
  verticalDivider: {
    background: '#B9B9C3',
    height: '1.042vw',
    width: '0.052vw',
    marginX: '0.833vw',
  },
  projectSubtitle: {
    fontSize: '0.729vw',
    color: '#6E6B7B',
  },
  breadcumbText: {
    fontSize: '0.729vw',
    fontWeight: 600,
    lineHeight: '160%',
  },
  pageContentWrapper: {
    marginTop: '0.625vw',
    display: 'flex',
    justifyContent: 'space-between',
  },
  nameLabel: {
    fontSize: '0.833vw',
    fontWeight: 600,
    color: 'black',
  },
  organizeProjectBtn: {
    minWidth: '7.969vw',
    maxWidth: '7.969vw',
    width: '7.969vw',
    marginLeft: '2.917vw',
    fontSize: '0.729vw',
    height: '1.042vw',
  },
  downloadBtn: {
    minWidth: '5.260vw',
    maxWidth: '5.260vw',
    width: '5.260vw',
    padding: 0,
    height: '1.042vw',
    marginLeft: '1.302vw',
    fontSize: '0.729vw',
  },
  uploadedFilesWrapper: {
    marginTop: '2.292vw',
  },
  finishCreationBtn: {
    marginTop: '2.292vw',
    width: '14.271vw',
    height: '2.500vw',
    display: 'flex',
    fontSize: '0.729vw',
    color: 'white',
  },
  fileNameText: {
    marginRight: '0.313vw',
    fontSize: '0.833vw',
    fontWeight: 600,
    lineHeight: '140%',
  },
  fileSizeText: { color: 'gray.500', fontSize: '0.833vw', fontWeight: 600, lineHeight: '140%' },
  progressBar: {
    width: '55.469vw',
    height: '0.417vw',
    marginLeft: '1.354vw',
    marginTop: '0.365vw',
    borderRadius: '0.521vw',
  },
  progressValueText: {
    marginLeft: '0.313vw',
    fontSize: '0.625vw',
    fontWeight: 600,
    lineHeight: '186%',
    color: 'purple.100',
  },

  accordionWrapper: {
    width: '14.271vw',
  },
  accordionTitleText: {
    fontSize: '0.833vw',
    lineHeight: '140%',
    fontWeight: 600,
  },
  accordionBtn: {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    padding: 0,
    margin: 0,
  },
  accordionFileWrapper: {
    alignItems: 'center',
    height: '1.667vw',
    cursor: 'pointer',
  },
  filePreviewWrapper: {
    flexDirection: 'column',
    padding: '3.125vw',
    width: '100%',
  },
  previewFileNameText: {
    lineHeight: '93%',
    fontSize: '1.250vw',
    fontWeight: 600,
  },
  previewFileCountText: {
    fontWeight: 600,
    lineHeight: '140%',
    fontSize: '0.833vw',
    marginLeft: '0.313vw',
  },
  previewFileSizeText: {
    fontWeight: 600,
    lineHeight: '140%',
    fontSize: '0.833vw',
    color: 'gray.500',
  },
  previewFileDivider: {
    width: '75.104vw',
    height: '0.052vw',
    background: '#ECECEC',
    marginTop: '2.292vw',
    marginBottom: '4.167vw',
  },
  previewNotAvailableText: {
    lineHeight: '93%',
    fontWeight: 600,
    fontSize: '1.250vw',
    color: 'purple.100',
    marginTop: '1.354vw',
  },
  previewUpdatingText: {
    fontWeight: 600,
    lineHeight: '112%',
    fontSize: '1.042vw',
    marginTop: '1.354vw',
    marginBottom: '0.833vw',
  },
  previewProgress: {
    width: '26.979vw',
    height: '0.417vw',
    borderRadius: '0.521vw',
  },
  previewProgressValueText: {
    fontWeight: 600,
    lineHeight: '186%',
    fontSize: '0.625vw',
    color: 'purple.100',
    marginLeft: '0.313vw',
  },
  browseBtn: {
    borderRadius: '0.313vw 0vw 0vw 0.313vw',
    width: '8.438vw',
    justifyContent: 'flex-start',
    fontSize: '0.729vw',
    height: '2.500vw',
    padding: '0 0 0 0.625vw',
  },
  searchBtn: {
    width: '8.438vw',
    justifyContent: 'flex-start',
    fontSize: '0.729vw',
    height: '2.500vw',
    borderRadius: 'none',
    padding: '0 0 0 0.625vw',
  },
  rulesBtn: {
    width: '8.438vw',
    justifyContent: 'flex-start',
    fontSize: '0.729vw',
    height: '2.500vw',
    borderRadius: 'none',
    padding: '0 0 0 0.625vw',
  },
  errorReportBtn: {
    width: '8.438vw',
    justifyContent: 'flex-start',
    fontSize: '0.729vw',
    height: '2.500vw',
    borderRadius: 'none',
    padding: '0 0 0 0.625vw',
  },
  modelBtn: {
    borderRadius: '0vw 0.313vw 0.313vw 0vw',
    width: '8.438vw',
    justifyContent: 'flex-start',
    fontSize: '0.729vw',
    height: '2.500vw',
    padding: '0 0 0 0.625vw',
  },
  activeDot: {
    borderRadius: '50%',
    background: 'green.100',
    height: '0.417vw',
    width: '0.417vw',
    position: 'absolute',
    right: '0.625vw',
  },
  headingWrapper: {
    justifyContent: 'space-between',
    width: '100%',
  },
  projectNameText: {
    fontSize: '0.938vw',
    fontWeight: 600,
    lineHeight: '124%',
  },
  infoBlocksWrapper: { justifyContent: 'space-between', width: '100%', marginTop: '2.083vw' },
  sheetListWrapper: {
    width: '64.115vw',
    height: '35.833vw',
    borderRadius: '0.313vw',
    border: '0.052vw solid #7368F0',
    padding: '2.083vw 0.833vw 0.938vw 2.083vw',
  },
  sheetListInnerWrapper: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.313vw',
    },
    '&::-webkit-scrollbar-track': {
      width: '0.313vw',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#6E6B7B',
      borderRadius: '1.250vw',
    },
    position: 'relative',
  },
  fileInfoWrapper: {
    borderRadius: '0.313vw',
    border: '0.052vw solid #ECECEC',
    width: '14.115vw',
    height: '35.833vw',
    padding: '0.938vw',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginLeft: '0.938vw',
  },
  fileInfoTitle: {
    fontSize: '0.729vw',
    fontWeight: 500,
    lineHeight: '160%',
    color: 'gray.500',
    marginBottom: '0.417vw',
  },
  fileInfoSubtitle: {
    fontSize: '0.729vw',
    fontWeight: 600,
    lineHeight: '160%',
  },
  fileInfoDivider: {
    width: '12.240vw',
    height: '0.052vw',
    background: '#ECECEC',
    alignSelf: 'center',
    marginY: '0.625vw',
  },
  paginationWrapper: {
    position: 'absolute',
    bottom: '0.938vw',
    alignItems: 'center',
  },
  jumpToSheetText: {
    fontSize: '0.729vw',
    lineHeight: '160%',
    fontWeight: 600,
    marginRight: '0.833vw',
  },
  sheetsCountText: {
    fontSize: '0.729vw',
    lineHeight: '160%',
    fontWeight: 600,
    color: 'gray.500',
    marginLeft: '0.833vw',
  },
};
