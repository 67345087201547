const EyeIcon = ({ fill = '#6E6B7B' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1.042vw'
      height='1.042vw'
      fill='none'
      viewBox='0 0 20 21'
    >
      <path
        fill={fill}
        d='M10 3c4.493 0 8.232 3.233 9.016 7.5a9.17 9.17 0 01-18.032 0C1.767 6.233 5.507 3 10 3zm0 13.333a7.504 7.504 0 007.314-5.833 7.504 7.504 0 00-14.628 0A7.504 7.504 0 0010 16.333zm0-2.083a3.75 3.75 0 110-7.5 3.75 3.75 0 010 7.5zm0-1.667a2.083 2.083 0 100-4.166 2.083 2.083 0 000 4.166z'
      ></path>
    </svg>
  );
};

export default EyeIcon;
