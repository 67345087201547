import React from 'react';

const DragMoveIcon = ({ fill }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1.042vw'
      height='1.042vw'
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        fill={fill}
        d='M10 18.333L6.667 15h6.666L10 18.333zm0-16.666L13.333 5H6.667L10 1.667zm0 10a1.666 1.666 0 110-3.333 1.666 1.666 0 010 3.333zM1.667 10L5 6.667v6.666L1.667 10zm16.666 0L15 13.333V6.667L18.333 10z'
      ></path>
    </svg>
  );
};

export default DragMoveIcon;
