import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Flex,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import CheckboxCircleIcon from '../../../assets/icons/CheckboxCircleIcon';
import ChevronRightIcon from '../../../assets/icons/ChevronRightIcon';
import DownloadIcon from '../../../assets/icons/DownloadIcon';
import FileListIcon from '../../../assets/icons/FileListIcon';
import FolderLineIcon from '../../../assets/icons/FolderLineIcon';
import FolderOpenIcon from '../../../assets/icons/FolderOpen';
import GearListIcon from '../../../assets/icons/GearList';
import ListIcon from '../../../assets/icons/ListIcon';
import TileIcon from '../../../assets/icons/TileIcon';
import AddFileWindow from '../../../components/add-file-window';
import Card from '../../../components/card';
import TextField from '../../../components/text-field';
import { useFileUpload } from '../../../hooks';
import { PROJECT_DATA } from '../../../mock-data';
import { FileCard } from './file-card';
import { FileContent } from './file-content';
import { styles } from './styles';

const AddProject = () => {
  const { selectedFiles, getRootProps, getInputProps } = useFileUpload();
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const [selectedFile, setSelectedFile] = React.useState('');
  const [projectName, setProjectName] = React.useState('');
  const [fileList, setFileList] = React.useState([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    selectedFiles.map((file) => {
      setFileList([...fileList, { fileData: file, isLoading: true }]);
      return null;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles]);

  // states for fake functional
  const [creationStatus, setCreationStatus] = React.useState(false);
  const [fileLoadingStatus, setFileLoadingStatus] = React.useState(true);

  React.useEffect(() => {
    if (fileList?.length === 0) {
      setFileLoadingStatus(true);
    }
    if (fileList.length > 0) {
      fileList?.map((file) => {
        setFileLoadingStatus(file.isLoading);
        return null;
      });
    }
    forceUpdate();
  }, [fileList, fileList.length]);

  const handleSelectFileOnLoad = (data) => {
    let arrayDepth = 0;
    let result = null;
    result = data?.folders
      ?.map((folder) => {
        arrayDepth += 1;
        if (folder.files) {
          return [folder.folderName, folder?.files[0]];
        }
        if (!folder.files) {
          return handleSelectFileOnLoad(folder);
        }
        return null;
      })
      .flat(arrayDepth);
    return result;
  };

  React.useEffect(() => {
    if (creationStatus) {
      setSelectedFile(
        `${handleSelectFileOnLoad(PROJECT_DATA)[0]}/${
          handleSelectFileOnLoad(PROJECT_DATA)[1]?.fileName
        }`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creationStatus]);

  const handleProjectNameChange = (event) => {
    setProjectName(event.target.value);
  };

  const handleFinishProjectCreating = () => {
    setCreationStatus(true);
  };

  const handleFileSearch = (projectData) => {
    const query = selectedFile?.split('/');
    let arrayDepth = 0;
    if (selectedFile) {
      return projectData?.folders
        ?.map((projectFolder) => {
          arrayDepth += 1;
          if (projectFolder?.folderName !== query[0]) {
            return handleFileSearch(projectFolder);
          }
          if (projectFolder?.folderName === query[0]) {
            return projectFolder?.files.find((folderFile) => folderFile?.fileName === query[1]);
          }
          return null;
        })
        .flat(arrayDepth)
        .find((item) => item !== undefined);
    }
    return null;
  };

  const renderFileAccordion = () => {
    const mapProjectContent = (projectContent) => {
      if (projectContent?.folders) {
        return projectContent.folders.map((folder, idx) => (
          <Box key={idx}>
            <Accordion allowMultiple>
              <AccordionItem mt='0.625vw'>
                {({ isExpanded }) => (
                  <>
                    <AccordionButton>
                      <Flex alignItems='center' ml={`${0.521 + folder.depthLevel / 2}vw`}>
                        {isExpanded ? <FolderOpenIcon /> : <FolderLineIcon />}
                        <Text ml='0.313vw'>{folder.folderName}</Text>
                      </Flex>
                      <AccordionIcon fontSize='1.042vw' />
                    </AccordionButton>
                    <AccordionPanel mt='0.625vw'>
                      {folder?.files?.map((file, fileIdx) => (
                        <Flex
                          key={folder.folderName + fileIdx}
                          sx={styles.accordionFileWrapper}
                          background={
                            selectedFile === `${folder.folderName}/${file.fileName}`
                              ? 'purple.100'
                              : 'unset'
                          }
                          onClick={() => setSelectedFile(`${folder.folderName}/${file.fileName}`)}
                          pl={`${0.521 + file.depthLevel / 2}vw`}
                        >
                          <FileListIcon />
                          <Text ml='0.313vw'>{file.fileName}</Text>
                        </Flex>
                      ))}
                      {mapProjectContent(folder)}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
            {folder?.depthLevel === 0 && projectContent.folders.length > idx + 1 ? (
              <Box sx={styles.divider} />
            ) : null}
          </Box>
        ));
      }
      return null;
    };

    return (
      <Flex>
        <Accordion sx={styles.accordionWrapper} variant='unstyled' allowMultiple>
          <AccordionItem>
            <AccordionButton sx={styles.accordionBtn}>
              <Text sx={styles.accordionTitleText}>{PROJECT_DATA?.projectName}</Text>
              <AccordionIcon fontSize='1.042vw' />
            </AccordionButton>
            <AccordionPanel>{mapProjectContent(PROJECT_DATA)}</AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
    );
  };

  const renderProjectInputs = () => {
    return (
      <Flex flexDirection='column'>
        <Text sx={styles.nameLabel}>Project name</Text>
        <Flex alignItems='center' mt='0.625vw'>
          <TextField
            wrapperProps={{ width: '57.031vw', height: '2.813vw' }}
            placeholder='Enter project name'
            onChange={(e) => handleProjectNameChange(e)}
          />
          <Button variant='borderless' sx={styles.organizeProjectBtn}>
            <Flex alignItems='center'>
              <GearListIcon />
              <Text ml='0.313vw'>Organize Project</Text>
            </Flex>
          </Button>
          <Button variant='borderless' sx={styles.downloadBtn}>
            <Flex alignItems='center'>
              <DownloadIcon />
              <Text ml='0.313vw'>Download</Text>
            </Flex>
          </Button>
        </Flex>
        <div {...getRootProps()}>
          <input {...getInputProps()} name='file' />
          <AddFileWindow wrapperProps={{ marginTop: '2.292vw' }} />
        </div>
        <VStack spacing='0.625vw' sx={styles.uploadedFilesWrapper}>
          {fileList?.map((file, idx) => (
            <FileCard
              id={idx}
              key={idx}
              file={file.fileData}
              fileList={fileList}
              setFileList={setFileList}
              forceUpdate={forceUpdate}
              setFileLoadingStatus={setFileLoadingStatus}
            />
          ))}
        </VStack>
        {!fileLoadingStatus && projectName !== '' ? (
          <Button
            variant='success'
            sx={styles.finishCreationBtn}
            onClick={() => handleFinishProjectCreating()}
          >
            <CheckboxCircleIcon />
            <Text ml='0.313vw'>Finish creating a project</Text>
          </Button>
        ) : null}
      </Flex>
    );
  };

  return (
    <Box sx={styles.projectsWrapper}>
      <Card wrapperProps={{ height: '4.167vw', width: '98.125vw', padding: '0.833vw 0.729vw' }}>
        <Box sx={styles.projectsHeader}>
          {projectName ? (
            <Flex alignItems='center'>
              <Text sx={styles.projectsHeading}>{projectName}</Text>
              <Box sx={styles.verticalDivider} />
              {projectName && fileList.length && selectedFile ? (
                <Breadcrumb separator={<ChevronRightIcon />} spacing='0.104vw' fontSize='1vw'>
                  {selectedFile?.split('/').map((breadcrumb, idx) => (
                    <BreadcrumbItem key={idx}>
                      <Text sx={styles.breadcumbText} color={idx === 0 ? 'purple.100' : 'gray.500'}>
                        {breadcrumb}
                      </Text>
                    </BreadcrumbItem>
                  ))}
                </Breadcrumb>
              ) : (
                <Text sx={styles.projectSubtitle}>Adding files</Text>
              )}
            </Flex>
          ) : (
            <Text sx={styles.projectsHeading}>Create new project</Text>
          )}
          <Box sx={styles.rightBlock}>
            <IconButton icon={<ListIcon />} variant='unstyled' ml='1vw' size='1.042vw' />
            <IconButton icon={<TileIcon />} variant='ustyled' ml='1vw' size='1.042vw' />
            <Button variant='secondary' sx={styles.cancelBtn} onClick={() => navigate('/projects')}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Card>
      <Box sx={styles.pageContentWrapper}>
        <Card wrapperProps={{ width: '16.146vw', padding: '0.938vw', minHeight: '41.563vw' }}>
          {creationStatus ? (
            renderFileAccordion(PROJECT_DATA)
          ) : (
            <Flex flexDirection='column'>
              <Text sx={styles.newProjectText}>New Project</Text>
              <Box sx={styles.divider} />
            </Flex>
          )}
        </Card>
        <Card
          wrapperProps={{
            width: '81.354vw',
            minHeight: '41.563vw',
            marginLeft: '0.625vw',
            padding: creationStatus ? 0 : '3.125vw',
          }}
        >
          {selectedFile ? (
            <FileContent
              fileData={handleFileSearch(PROJECT_DATA)}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
            />
          ) : (
            renderProjectInputs()
          )}
        </Card>
      </Box>
    </Box>
  );
};

export default AddProject;
