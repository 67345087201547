import React from 'react';

const ChevronRightIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1.042vw'
      height='1.042vw'
      fill='none'
      viewBox='0 0 20 21'
    >
      <path
        fill='#6E6B7B'
        d='M10.977 10.5L6.852 6.375 8.03 5.197l5.303 5.303-5.303 5.303-1.178-1.178 4.125-4.125z'
      ></path>
    </svg>
  );
};

export default ChevronRightIcon;
