export const styles = {
  menuBtn: {
    height: '2.500vw',
    width: '21.875vw',
    borderRadius: '0.313vw',
    padding: '0.833vw 0.625vw',
    display: 'flex',
    border: 'none',

    _hover: {
      background: 'default',
    },
    _focus: {
      background: 'default',
    },
    _active: {
      background: 'default',
    },
  },
  menuBtnText: {
    fontSize: '0.729vw',
  },
  menuList: {
    boxShadow: '0 0.208vw 1.250vw rgba(34, 41, 47, 0.15)',
    borderRadius: '0.313vw',
    maxWidth: '21.875vw',
    minWidth: '21.875vw',
    height: '100%',
    fontSize: '0.729vw',
    overflow: 'hidden',
    border: 'none',
    padding: '0.625vw 0 0.625vw 0',
    margin: 0,
    zIndex: 10,
  },
  scrollWrapper: {
    flexDirection: 'column',
    marginRight: '0.208vw',
    height: 'inherit',
    maxHeight: '20.833vw',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.208vw',
    },
    '&::-webkit-scrollbar-track': {
      width: '0.208vw',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#6E6B7B',
      borderRadius: '0.208vw',
    },
  },
  accordionBtn: {
    paddingRight: '0.625vw',
    paddingLeft: '0.625vw',
    height: '1.667vw',
  },
  accordionBtnText: {
    fontWeight: 600,
    fontSize: '0.729vw',
    lineHeight: '160%',
  },
  checkboxWrapper: {
    height: '1.667vw',
    paddingLeft: '0.938vw',
    alignItems: 'center',
    width: '100%',
  },
  sheetItemsDivider: {
    width: '11.146vw',
    height: '0.052vw',
    background: 'gray.50',
    marginY: '0.625vw',
  },
  accordionWrapper: {
    width: '100%',
  },
  accordionTitleText: {
    fontSize: '0.833vw',
    lineHeight: '140%',
    fontWeight: 600,
  },
  elementsAccordionBtn: {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    height: '1.042vw',
    padding: 0,
    margin: 0,
  },
  accordionFileWrapper: {
    alignItems: 'center',
    width: '100%',
    padding: 0,
    margin: 0,
    height: '1.667vw',
  },
  customCheckbox: (isSelected) => ({
    border: isSelected ? 'unset' : '0.08vw solid black',
    background: isSelected ? 'purple.100' : 'default',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10%',
    height: '0.625vw',
    width: '0.625vw',
    cursor: 'pointer',
  }),
};
