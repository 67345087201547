import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Flex,
  Menu,
  MenuButton,
  // MenuItem,
  MenuList,
  Text,
  useCheckboxGroup,
} from '@chakra-ui/react';

import ArrowDownIcon from '../../../../assets/icons/ArrowDownIcon';
import ArrowUpIcon from '../../../../assets/icons/ArrowUpIcon';
import CustomCheckbox from '../../../../components/checkbox';
import { styles } from './styles';

const RULES = [
  {
    ruleCategoryName: 'ADA 2010',
    rules: [
      { ruleName: 'ADA 1124' },
      { ruleName: 'ADA 2411' },
      { ruleName: 'ADA 17' },
      { ruleName: 'ADA 71' },
    ],
  },
  {
    ruleCategoryName: 'NEC 2020 (NFPA 70)',
    rules: [
      { ruleName: 'NEC 1124' },
      { ruleName: 'NEC Plugs 2411' },
      { ruleName: 'NEC Plugs 17' },
      { ruleName: 'NEC 71' },
    ],
  },
  {
    ruleCategoryName: 'NEC 2022 (NFPA 72)',
    rules: [{ ruleName: 'NEC NFPA 33' }, { ruleName: 'NEC NFPA 85' }, { ruleName: 'NEC NFPA 94' }],
  },
];

export const DropdownMenu = ({ wrapperProps }) => {
  const { value, getCheckboxProps } = useCheckboxGroup();

  const checkRuleStatus = (ruleName) => {
    if (value.find((rule) => rule === ruleName)) {
      return true;
    }
    return false;
  };

  checkRuleStatus('ADA 1124');

  return (
    <Menu {...wrapperProps}>
      {({ isOpen }) => (
        <>
          <MenuButton as={Button} sx={styles.menuBtn}>
            <Flex justifyContent='space-between'>
              <Text sx={styles.menuBtnText}>Rules</Text>
              {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </Flex>
          </MenuButton>
          <MenuList sx={styles.menuList}>
            <Flex sx={styles.scrollWrapper}>
              {RULES.map((category, categoryIdx) => (
                <Accordion key={categoryIdx} allowMultiple w='99%'>
                  <AccordionItem>
                    <AccordionButton sx={styles.accordionBtn}>
                      <Text sx={styles.accordionBtnText}>{category.ruleCategoryName}</Text>
                      <AccordionIcon fontSize='1.042vw' />
                    </AccordionButton>
                    <AccordionPanel>
                      {category?.rules?.map((rule, ruleIdx) => {
                        return (
                          <Flex
                            key={ruleIdx}
                            sx={styles.checkboxWrapper}
                            background={checkRuleStatus(rule.ruleName) ? '#7368F0' : 'unset'}
                          >
                            <CustomCheckbox
                              {...getCheckboxProps({ value: rule.ruleName })}
                              variant='inverted'
                            />
                            <Text
                              ml='0.313vw'
                              fontWeight={checkRuleStatus(rule.ruleName) ? 500 : 600}
                              color={checkRuleStatus(rule.ruleName) ? '#fff' : 'unset'}
                            >
                              {rule.ruleName}
                            </Text>
                          </Flex>
                        );
                      })}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              ))}
            </Flex>
          </MenuList>
        </>
      )}
    </Menu>
  );
};
