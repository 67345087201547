export const styles = {
  inputWrapper: {
    fontSize: '0.833vw',
    fontFamily: 'Montserrat',
  },
  groupStyle: {
    fontSize: '0.833vw',
    display: 'flex',
    flexDirection: 'column',
  },
  inputStyle: {
    height: 'inherit',
    paddingLeft: '0.625vw',
    background: 'gray.100',
    fontSize: '0.833vw',
    border: '0.052vw #625F6E solid',
    borderRadius: '0.313vw',
    _placeholder: {
      transition: '0.2s ease all',
      fontWeight: 500,
      color: 'gray.300',
      fontSize: '0.833vw',
    },
    _hover: {
      borderColor: 'gray.500',
    },
    _focus: {
      borderColor: 'purple.100',
      boxShadow: '0 0.156vw 0.521vw 0 rgba(0,0,0, 0.1)',
      _placeholder: {
        transform: 'translate(0.260vw)',
        transition: '0.2s ease all',
      },
    },
  },
  changeVisibilityBtn: {
    position: 'absolute',
    right: '1.250vw',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    maxHeight: '1.042vw',
    minWidth: '1.042vw',
  },
};
