export const styles = {
  fileWindowWrapper: {
    height: '10.938vw',
    width: '75.104vw',
    border: '0.052vw dashed #7368F0',
    borderRadius: '0.625vw',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  fileWindowTextWrapper: {
    marginLeft: '1.354vw',
  },
  addFileTitle: {
    fontWeight: 600,
    fontSize: '1.250vw',
    lineHeight: '93%',
    color: 'purple.100',
  },
  addFileSubtitle: {
    fontSize: '0.833vw',
    fontWeight: 600,
    lineHeight: '140%',
    color: 'gray.500',
  },
};
