import React from 'react';

const LeftArrowIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='0.833vw'
      height='0.833vw'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill='#000'
        d='M5.219 7.333h8.114v1.333H5.22l3.576 3.576-.943.943L2.667 8l5.185-5.186.943.943-3.576 3.576z'
      ></path>
    </svg>
  );
};

export default LeftArrowIcon;
