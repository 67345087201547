export const Button = {
  variants: {
    primary: {
      borderRadius: '0.313vw',
      background: 'purple.100',
      color: 'white',
      transition: '0s',
      height: '2.500vw',
      maxHeight: '2.500vw',
      _hover: {
        boxShadow: '0.1vw 0.417vw 1.250vw -0.417vw rgba(115, 103, 240, 1)',
      },
    },
    secondary: {
      borderRadius: '0.313vw',
      background: '#F6F6F6',
      color: '#625F6E',
      transition: '0s',
      height: '2.500vw',
      maxHeight: '2.500vw',
      _hover: {
        boxShadow: '0.1vw 0.417vw 1.250vw -0.417vw rgba(200, 200, 200, 1)',
      },
    },
    success: {
      borderRadius: '0.313vw',
      background: 'green.100',
      color: 'white',
      transition: '0s',
      minHeight: '2.500vw',
      maxHeight: '2.500vw',
      _hover: {
        boxShadow: '0.1vw 0.417vw 1.250vw -0.417vw rgba(40, 199, 111, 1)',
      },
    },
    borderless: {
      // background: 'red',
    },
  },
};
