const SignupIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1.042vw'
      height='1.042vw'
      fill='none'
      viewBox='0 0 20 21'
    >
      <path
        fill='#7368F0'
        d='M11.667 12.377v1.741A5 5 0 005 18.833H3.333a6.667 6.667 0 018.334-6.457zM10 11.333c-2.763 0-5-2.237-5-5 0-2.762 2.237-5 5-5 2.762 0 5 2.238 5 5 0 2.763-2.238 5-5 5zm0-1.666A3.332 3.332 0 1010 3a3.332 3.332 0 100 6.667zm5 5v-2.5h1.667v2.5h2.5v1.666h-2.5v2.5H15v-2.5h-2.5v-1.666H15z'
      ></path>
    </svg>
  );
};

export default SignupIcon;
