import { Box, Button, Flex, IconButton, SimpleGrid, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import AddIcon from '../../assets/icons/AddIcon';
import ListIcon from '../../assets/icons/ListIcon';
import TileIcon from '../../assets/icons/TileIcon';
import Card from '../../components/card';
import { PROJECTS } from '../../mock-data';
import ProjectCard from './components/project-card';
import { SortingMenu } from './components/sorting-menu';
import { styles } from './styles';

const ProjectsPage = () => {
  const navigate = useNavigate();
  return (
    <Box sx={styles.projectsWrapper}>
      <Card wrapperProps={{ height: '4.167vw', width: '98.125vw', padding: '0.833vw 0.729vw' }}>
        <Box sx={styles.projectsHeader}>
          <Text sx={styles.projectsHeading}>Projects</Text>
          <Box sx={styles.rightBlock}>
            <Text sx={styles.sortbyText}>Sort by:</Text>
            <SortingMenu />
            <IconButton icon={<ListIcon />} variant='unstyled' ml='1vw' size='1.042vw' />
            <IconButton icon={<TileIcon />} variant='ustyled' ml='1vw' size='1.042vw' />
            <Button
              variant='primary'
              sx={styles.addProjectBtn}
              onClick={() => navigate('add-project')}
            >
              <Flex alignItems='center'>
                <AddIcon />
                <Text ml='0.313vw'>Add project</Text>
              </Flex>
            </Button>
          </Box>
        </Box>
      </Card>
      <Card
        wrapperProps={{
          width: '98.125vw',
          height: '76vh',
          background: 'red',
          marginTop: '0.625vw',
          padding: '4vh 1.5vw',
          overflow: 'hidden',
        }}
      >
        <SimpleGrid columns='6' spacing='2.083vw' sx={styles.gridWrapper}>
          {PROJECTS.map((projectData, idx) => (
            <ProjectCard key={idx} projectData={projectData} />
          ))}
        </SimpleGrid>
      </Card>
    </Box>
  );
};

export default ProjectsPage;
