import {
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import React from 'react';

import EyeIcon from '../../assets/icons/EyeIcon';
import EyeInvisibleIcon from '../../assets/icons/EyeInvisibleIcon';
import { styles } from './styles';

const TextField = ({
  inputType = 'text',
  placeholder,
  onChange,
  required,
  name,
  value,
  onBlur,
  touched,
  error,
  disabled,
  min,
  max,
  wrapperProps,
}) => {
  const [currentInputType, setCurrentInputType] = React.useState(inputType);
  const [passwordVisibility, setPasswordVisiility] = React.useState(false);

  const handleChangePasswordVisibility = () => {
    setPasswordVisiility(!passwordVisibility);
    if (currentInputType === 'password') {
      setCurrentInputType('text');
    }
    if (currentInputType !== 'password') {
      setCurrentInputType('password');
    }
  };

  return (
    <FormControl isInvalid={Boolean(error && touched)} sx={styles.inputWrapper}>
      <InputGroup sx={styles.groupStyle} {...wrapperProps}>
        <Input
          required={required}
          placeholder={placeholder}
          type={currentInputType}
          disabled={disabled}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          sx={styles.inputStyle}
          min={min}
          max={max}
          variant='undtyled'
          padding={0}
          maxH='2.813vw'
        />
        {inputType === 'password' ? (
          <InputRightElement h='100%'>
            <IconButton
              variant='unstyled'
              icon={passwordVisibility ? <EyeInvisibleIcon /> : <EyeIcon />}
              onClick={handleChangePasswordVisibility}
              sx={styles.changeVisibilityBtn}
            />
          </InputRightElement>
        ) : null}
        {error && touched ? (
          <FormErrorMessage fontSize='0.729vw' pos='absolute' top='3vw' margin={0}>
            {error}
          </FormErrorMessage>
        ) : null}
      </InputGroup>
    </FormControl>
  );
};

export default TextField;
