import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import LoginIcon from '../../assets/icons/LoginIcon';
import SignupIcon from '../../assets/icons/SignupIcon';
import Card from '../../components/card';
import TextField from '../../components/text-field';
import { loginSchema } from '../../validation/schemas';
import { styles } from './styles';

const LoginPage = () => {
  const navigate = useNavigate();
  return (
    <Box sx={styles.pageWrapper}>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={loginSchema}
        onSubmit={(values, actions) => {
          if (values.email !== 'den@drawer.ai') {
            actions.setFieldError('email', 'Account does not exist');
          }
          if (values.password !== 'q1w2e3') {
            actions.setFieldError('password', 'Incorrect password');
          }
          if (values.password === 'q1w2e3' && values.email === 'den@drawer.ai') {
            navigate('/projects');
          }
        }}
      >
        <Form>
          <Card
            wrapperProps={{
              width: '23.958vw',
              padding: '1.563vw 1.563vw 1.042vw',
              flexDirection: 'column',
            }}
          >
            <Heading sx={styles.authHeading}>Sign In</Heading>
            <Text sx={styles.inputLabel}>Email</Text>
            <Field name='email'>
              {({ field, meta }) => (
                <>
                  <TextField
                    placeholder='Enter your email'
                    touched={meta.touched}
                    error={meta.error}
                    required
                    {...field}
                    wrapperProps={{ height: '3.1vw' }}
                  />
                </>
              )}
            </Field>
            <Text sx={styles.inputLabel}>Password</Text>
            <Field name='password'>
              {({ field, meta }) => (
                <TextField
                  placeholder='Password'
                  inputType='password'
                  touched={meta.touched}
                  error={meta.error}
                  required
                  {...field}
                  wrapperProps={{ height: '2.813vw' }}
                />
              )}
            </Field>
            <Text sx={styles.forgotPasswordText}>Forgot password?</Text>
            <Button variant='primary' type='submit' sx={styles.loginBtn}>
              <Flex alignItems='center'>
                <LoginIcon />
                <Text ml='0.313vw'>Sign In</Text>
              </Flex>
            </Button>
            <Box sx={styles.dividerStyle} />
            <Flex sx={styles.signupWrapper}>
              <Text sx={styles.noAccountText}>No account yet?</Text>
              <Button variant='unstyled' sx={styles.signupBtn}>
                <Flex alignItems='center'>
                  <SignupIcon />
                  <Text ml='0.313vw'> Sign up</Text>
                </Flex>
              </Button>
            </Flex>
          </Card>
        </Form>
      </Formik>
    </Box>
  );
};

export default LoginPage;
