import React from 'react';

const FolderUploadIcon = ({ fill }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1.042vw'
      height='1.042vw'
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        fill={fill}
        d='M10.345 4.167H17.5a.833.833 0 01.833.833v11.667a.833.833 0 01-.833.833h-15a.833.833 0 01-.833-.833V3.333A.833.833 0 012.5 2.5h6.178l1.667 1.667zm-7.012 0v11.666h13.334v-10H9.655L7.988 4.167H3.333zm7.5 6.666v3.334H9.167v-3.334h-2.5L10 7.5l3.333 3.333h-2.5z'
      ></path>
    </svg>
  );
};

export default FolderUploadIcon;
