export const styles = {
  menuBtn: {
    position: 'relative',
    cursor: 'pointer',
    marginLeft: '2.500vw',
  },
  notificationDot: {
    background: 'red.100',
    height: '0.990vw',
    width: '0.990vw',
    borderRadius: 'full',
    position: 'absolute',
    top: '-0.4vw',
    left: '0.4vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '0.625vw',
  },
};
