export const styles = {
  sheetPageWrapper: {
    flexDirection: 'column',
    paddingTop: '0.6vw',
    height: '100vh',
  },
  headBlockInnerWrapper: {
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  headBtn: {
    maxWidth: '2.500vw',
    minWidth: '2.500vw',
    zIndex: 2,
    padding: 0,
  },
  sheetNameText: {
    fontSize: '1.250vw',
    lineHeight: '117%',
    fontWeight: 600,
    color: 'gray.400',
    marginLeft: '0.833vw',
  },
  modeNameText: {
    fontSize: '0.729vw',
    lineHeight: '160%',
    fontWeight: 600,
    color: 'gray.200',
    marginLeft: '0.417vw',
  },
  modeBtnsWrapper: {
    border: '0.052vw solid #F8F8F8',
    height: '2.500vw',
    marginRight: '2.083vw',
  },
  blueprintWrapper: {
    width: '100%',
    height: '100%',
    border: '0.052vw solid #7368F0',
    borderRadius: '0.313vw',
    position: 'relative',
    overflow: 'hidden',
    background: 'gray.50',
  },
  blueprintImage: {
    // height: '42.5vw',
    // justifySelf: 'center',
  },
  elementMarker: {
    background: 'rgba(40, 199, 111, 0.5)',
    border: '0.052vw solid rgba(40, 199, 111, 0.8)',
    borderRadius: '0.208vw',
    cursor: 'pointer',
  },
  infoBtn: {
    minWidth: '2.396vw',
    maxWidth: '2.396vw',
    position: 'absolute',
    right: '0.833vw',
    top: '0.833vw',
    padding: 0,
    display: 'flex',
  },
  infoTab: {
    borderLeft: '0.052vw solid #7368F0',
    height: '100%',
    width: '16.771vw',
    position: 'absolute',
    padding: '0.938vw',
    background: 'white',
    cursor: 'default',
    right: 0,
  },
  propsText: {
    fontSize: '0.833vw',
    lineHeight: '140%',
    fontWeight: 600,
  },
  infoTabBtn: {
    maxWidth: '0.833vw',
    minWidth: '0.833vw',
    maxHeight: '0.833vw',
    minHeight: '0.833vw',
    padding: 0,
  },
  propNameText: {
    fontSize: '0.729vw',
    lineHeight: '140%',
    fontWeight: 500,
    color: 'gray.500',
  },
  propValueText: {
    fontSize: '0.729vw',
    lineHeight: '160%',
    fontWeight: 600,
    marginTop: '0.417vw',
  },
  infoTabDivider: {
    width: '14.896vw',
    height: '0.052vw',
    background: 'gray.50',
    marginTop: '0.625vw',
  },
  sheetItemsText: {
    fontSize: '0.938vw',
    lineHeight: '124%',
    fontWeight: 600,
    color: 'gray.400',
    // marginBottom: '1.354vw',
  },
  itemsTreeWrapper: {
    flexDirection: 'column',
    overflowY: 'auto',
    height: '85vh',
    '&::-webkit-scrollbar': {
      width: '0.208vw',
    },
    '&::-webkit-scrollbar-track': {
      width: '0.208vw',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#6E6B7B',
      borderRadius: '0.208vw',
    },
  },
  selectViewBtnsWrapper: {
    position: 'absolute',
    bottom: '1.2vw',
  },
  elementsBtn: {
    height: '1.667vw',
    minWidth: '5.521vw',
    maxWidth: '5.521vw',
    borderRadius: '0.313vw 0 0 0.313vw',
    fontSize: '0.729vw',
    padding: 0,
  },
  drawingBtn: {
    height: '1.667vw',
    minWidth: '5.521vw',
    maxWidth: '5.521vw',
    fontSize: '0.729vw',
    borderRadius: '0 0.313vw 0.313vw 0',
    padding: 0,
  },
};
