export const styles = {
  headerWrapper: {
    height: '3.125vw',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    paddingX: '1.667vw',
    justifyContent: 'space-between',
  },
  headerFunctionalWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  projectsBtn: {
    minWidth: '7.552vw',
    width: '7.552vw',
    fontSize: '0.833vw',
    height: '2.083vw',
  },
  knowledgebaseBtn: {
    fontSize: '0.833vw',
    height: '2.083vw',
    width: '10.729vw',
    marginLeft: '0.833vw',
  },
};
