import { chakra, Flex, useCheckbox } from '@chakra-ui/react';

import CheckIcon from '../../assets/icons/CheckIcon';
import { styles } from './styles';

const CustomCheckbox = (props) => {
  const { state, getCheckboxProps, getInputProps, htmlProps } = useCheckbox(props);
  return (
    <chakra.label w='0.625vw' {...htmlProps}>
      <input {...getInputProps()} hidden />
      <Flex sx={styles.customCheckbox(state.isChecked, props.variant)} {...getCheckboxProps()}>
        {state.isChecked ? (
          <CheckIcon fill={props.variant === 'inverted' ? '#7368F0' : '#fff'} />
        ) : null}
        {state.isIndeterminate && !state.isChecked ? (
          <Flex width='65%' background='black' height='0.08vw' />
        ) : null}
      </Flex>
    </chakra.label>
  );
};

export default CustomCheckbox;
