const AddCircleIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1.042vw'
      height='1.042vw'
      fill='none'
      viewBox='0 0 20 21'
    >
      <path
        fill='#B9B9C3'
        d='M10 18.833a8.333 8.333 0 110-16.666 8.333 8.333 0 110 16.666zm-.833-9.166H5.833v1.666h3.334v3.334h1.666v-3.334h3.334V9.667h-3.334V6.333H9.167v3.334z'
      ></path>
    </svg>
  );
};

export default AddCircleIcon;
