import { Box } from '@chakra-ui/react';

import { styles } from './styles';

const Card = ({ children, wrapperProps }) => {
  return (
    <Box sx={styles.cardWrapper} {...wrapperProps}>
      {children}
    </Box>
  );
};

export default Card;
