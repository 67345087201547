const FolderIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1.042vw'
      height='1.042vw'
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        fill='#fff'
        d='M11.178 4.167h5.489A.833.833 0 0117.5 5v.833h-15v-2.5a.833.833 0 01.833-.833h6.179l1.666 1.667zM2.572 7.5h14.855a.833.833 0 01.831.902l-.695 8.334a.833.833 0 01-.83.764H3.267a.833.833 0 01-.83-.764l-.695-8.334a.833.833 0 01.83-.902z'
      ></path>
    </svg>
  );
};

export default FolderIcon;
