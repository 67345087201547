import { Flex, Progress, Text, VStack } from '@chakra-ui/react';
import React from 'react';

import DeleteIcon from '../../../assets/icons/DeleteIcon';
import FilePlusIcon from '../../../assets/icons/FilePlusIcon';
import PencilIcon from '../../../assets/icons/PencilIcon';
import Card from '../../../components/card';
import { styles } from './styles';

export const FileCard = ({
  file,
  fileList,
  setFileList,
  id,
  forceUpdate,
  setFileLoadingStatus,
}) => {
  const [progressValue, setProgressValue] = React.useState(0);

  React.useEffect(() => {
    if (progressValue <= 95) {
      setTimeout(
        () => setProgressValue(progressValue + 1),
        Math.floor(Math.random() * (35 - 1 + 1)) + 1,
      );
    }
    if (progressValue > 95) {
      setProgressValue(100);
    }
    if (progressValue === 100) {
      const result = fileList[id];
      result.isLoading = false;
      fileList.splice(id, 1, result);
      setFileList(fileList);
      setFileLoadingStatus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressValue]);

  const handleDeleteFile = () => {
    const result = fileList;
    result.splice(id, 1);
    setFileList(result);
    forceUpdate();
  };

  return (
    <Card
      wrapperProps={{
        width: '75.104vw',
        padding: '0.625vw',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Flex justifyContent='space-between' w='100%'>
        <Flex>
          <FilePlusIcon width='1.042vw' height='1.042vw' />
          <VStack spacing='0.104vw' align='flex-start' ml='0.313vw'>
            <Flex alignItems='center'>
              <Text sx={styles.fileNameText}>{file.name}</Text>
              <PencilIcon />
            </Flex>
            <Text sx={styles.fileSizeText}>2.0 mb</Text>
          </VStack>
        </Flex>
        <DeleteIcon onClick={handleDeleteFile} />
      </Flex>
      <Flex>
        <Progress value={progressValue} sx={styles.progressBar} colorScheme='pink' />
        <Text sx={styles.progressValueText}>{`${progressValue}%`}</Text>
      </Flex>
    </Card>
  );
};
