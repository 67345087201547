import React from 'react';

const GearListIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1.042vw'
      height='1.042vw'
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        fill='#000'
        d='M1.667 15H7.5v1.667H1.667V15zm0-5.833h7.5v1.666h-7.5V9.167zm0-5.834h16.666V5H1.667V3.333zm15.561 7.521l.964-.326.833 1.444-.763.67c.096.456.096.927 0 1.382l.763.671-.833 1.443-.964-.326a3.323 3.323 0 01-1.195.692l-.2.996h-1.666l-.2-.997a3.33 3.33 0 01-1.195-.691l-.964.326-.833-1.443.763-.67a3.347 3.347 0 010-1.383l-.763-.67.833-1.444.964.326a3.32 3.32 0 011.196-.691l.199-.996h1.666l.2.996c.447.145.854.384 1.195.692zM15 15a1.667 1.667 0 100-3.334A1.667 1.667 0 0015 15z'
      ></path>
    </svg>
  );
};

export default GearListIcon;
