const LoginIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1.042vw'
      height='1.042vw'
      fill='none'
      viewBox='0 0 21 21'
    >
      <path
        fill='#fff'
        d='M3.833 13H5.5v4.167h10V3.833h-10V8H3.833V3a.833.833 0 01.834-.833h11.666a.833.833 0 01.834.833v15a.833.833 0 01-.834.833H4.667A.833.833 0 013.833 18v-5zm5-3.333v-2.5L13 10.5l-4.167 3.333v-2.5H2.167V9.667h6.666z'
      ></path>
    </svg>
  );
};

export default LoginIcon;
