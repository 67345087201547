import { Box, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';

import DeleteIcon from '../../../assets/icons/DeleteIcon';
import DotsIcon from '../../../assets/icons/DotsIcon';
import DownloadIcon from '../../../assets/icons/DownloadIcon';
import EditIcon from '../../../assets/icons/EditIcon';
import GalleryIcon from '../../../assets/icons/GalleryIcon';
import { styles } from '../styles';

export const ProjectCardMenu = () => {
  return (
    <Menu>
      <MenuButton as={Box} sx={styles.cardMenuBtn} variant='unstyled'>
        <DotsIcon />
      </MenuButton>
      <MenuList sx={styles.projectCardMenuList}>
        <MenuItem sx={styles.projectCardMenuItem} mb='1.042vw'>
          <EditIcon />
          <Text ml='0.313vw'>Edit project</Text>
        </MenuItem>
        <MenuItem sx={styles.projectCardMenuItem} mb='1.042vw'>
          <GalleryIcon />
          <Text ml='0.313vw'>Add cover</Text>
        </MenuItem>
        <MenuItem sx={styles.projectCardMenuItem}>
          <DownloadIcon />
          <Text ml='0.313vw'>Download</Text>
        </MenuItem>
        <Box sx={styles.divider} />
        <MenuItem sx={styles.projectCardMenuItem}>
          <DeleteIcon />
          <Text ml='0.313vw' color='red.100'>
            Delete
          </Text>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
