import React from 'react';

const CrossPointIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='0.833vw'
      height='0.833vw'
      fill='none'
      viewBox='0 0 16 17'
    >
      <path
        fill='#000'
        d='M8 7.557l3.3-3.3.943.943-3.3 3.3 3.3 3.3-.943.943-3.3-3.3-3.3 3.3-.943-.943 3.3-3.3-3.3-3.3.943-.943 3.3 3.3z'
      ></path>
    </svg>
  );
};

export default CrossPointIcon;
