import React from 'react';

const ArrowUpIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='0.833vw'
      height='0.833vw'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill='#09121F'
        d='M8 7.219l-3.3 3.3-.943-.943L8 5.333l4.243 4.243-.943.943-3.3-3.3z'
      ></path>
    </svg>
  );
};

export default ArrowUpIcon;
